/*
 *   File : quiz-detail.js
 *   Author : https://evoqins.com
 *   Description :Quiz detail modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

// style 
import styles from "../../Style/Component/modal.module.scss";

//custom components
import { TextButton } from '../Buttons';
import { Icon } from '../Icon';

const QuizDetailModal = (props) => {

    const navigate = useNavigate();

    // handle close Modal 
    const _handleCloseModal = () => {
        var button = document.getElementById("quiz-detail-modal-close");
        button.click();
    };

    // handle edit quiz 
    const _handleEditQuiz = (course_id) => {
        _handleCloseModal();
        navigate('/manage-resource/manage-quiz', {
            state: {
                course_id: course_id,
                type: "edit"
            }
        });
    };

    return (
        <div className="modal fade" id="quiz-detail-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="quizDetailModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content e-border-radius-12 w-480px mx-auto ">
                    <img src={require("../../Assets/Image/CountryAdmin/close-icon.svg").default}
                        id='quiz-detail-modal-close'
                        className='d-none'
                        alt='close'
                        data-bs-dismiss="modal" />

                    <div className="modal-header px-4 pt-4 pb-2 border-0">
                        <div>
                            <h4 className="mb-0 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px">
                                Quiz details
                            </h4>
                            {/* <p className='mb-0 e-alt-font-noto-sans e-text-davys-grey e-font-14 e-font-weight-400 line-height-20px'>
                                Quiz details.
                            </p> */}
                        </div>
                        <Icon icon="close-icon"
                            className='cursor-pointer'
                            size="24px"
                            onPress={_handleCloseModal} />
                    </div>
                    <div className={`${styles.e_modal_body_wrapper} pt-3 modal-body ps-4 pe-3 pt-0 pb-4 me-2 h-422px overflow-auto e-modal-body-wrapper`}>
                        <div className='e-border-bottom-1px-dark-silver mb-3 '>
                            <h6 className='mb-2 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-18px'>
                                Quiz title
                            </h6>
                            <p className='mb-3 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-24px'>
                                {props.quiz.title}
                            </p>
                            <h6 className='mb-2 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-18px'>
                                Quiz description
                            </h6>
                            <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-24px'>
                                {props.quiz.description}
                            </p>

                            <div className='d-flex pt-3 gap-0-40'>
                                <div>
                                    <h6 className='mb-2 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-18px'>
                                        Quiz rewards
                                    </h6>
                                    <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-24px'>
                                        {props.quiz.points} points
                                    </p>
                                </div>
                                <div>
                                    <h6 className='mb-2 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-18px'>
                                        Quiz time
                                    </h6>
                                    <p className='mb-3 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-24px'>
                                        {props.quiz.duration_text}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {props.quiz.question.map((data, key) => (
                            <div key={key} className={key !== props.quiz.question.length - 1 ? `pb-4` : ``}>
                                <h6 className='mb-2 e-text-raisin-black e-alt-font-noto-sans e-font-16 e-font-weight-600 line-height-18px'>
                                    Question {key + 1}
                                </h6>
                                <p className='mb-3 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-24px'>
                                    {data.question}
                                </p>

                                <h6 className='mb-2 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-18px'>
                                    Add options
                                </h6>
                                {data.answers.map((data, key) => (
                                    <div key={key} className='d-flex gap-2'>
                                        <p className='mb-2 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-24px'>
                                            Option{key + 1}: {data.answer}

                                        </p>
                                        {
                                            data.is_answer === true &&
                                            <img src={require("../../Assets/Image/CountryAdmin/ManageResource/correct-answer.png")}
                                                className='mt-1'
                                                draggable={false}
                                                width={16}
                                                height={16}
                                                alt='correct' />
                                        }
                                    </div>
                                ))}
                                <h6 className='pt-1 mb-2 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-18px'>
                                    Hint
                                </h6>
                                <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-24px'>
                                    {data.hint}
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className='text-end px-4 pb-4'>
                        <div className='e-inline-flex justify-content-end gap-0-24px'>
                            <TextButton name="Edit"
                                className="e-font-16"
                                onPress={() => _handleEditQuiz(props.quiz.course_id)} />
                            <TextButton name="Delete"
                                className="e-text-lust e-font-16"
                                onPress={() => {
                                    props.handleDelete(props.quiz.course_id);
                                    _handleCloseModal();
                                }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(QuizDetailModal);