/*
 *   File : password-input.js
 *   Author : https://evoqins.com
 *   Description : Password Input component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo, useState, useRef } from 'react';

//custom style
import styles from "../../Style/Component/input.module.scss";

// Assets
import showIcon from "../../Assets/Image/Login/show.png";
import hideIcon from "../../Assets/Image/Login/hide.png";

const CustomPasswordInput = (props) => {
    const inputRef = useRef(null);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [show, setShow] = useState(false);

    //handle focus
    const _handleFocus = () => {
        setIsInputFocused(true);
    };

    const _handleBlur = () => {
        setIsInputFocused(false);
    };

    //input handle change
    const _handleChange = (e) => {
        let value = e.target.value;
        props.handleChange(value);

        // const masked_password = e.target.value.replace(/./g, '*');
        // props.handleChange(masked_password);
    };

    // handle enter key press
    const keyPress = (e) => {
        if (e.keyCode === 13) {
            props.onKeyPress();
        }
    }

    // handle show and hide password
    const _handleShow = () => {
        setShow(!show);
        if (inputRef.current) {
            setTimeout(() => {
                inputRef.current.focus();
                inputRef.current.setSelectionRange(
                    inputRef.current.value.length,
                    inputRef.current.value.length
                );
            }, 0);
        }
    }


    return (
        <div >
            {props.label &&
                <label className={`${props.labelStyle} e-text-rich-black e-font-weight-500 e-alt-font-noto-sans line-height-18px e-font-14 pb-2`}>
                    {props.label}
                    {props.require &&
                        <span className='e-text-lust'>*</span>
                    }
                </label>
            }

            <div className='h-64px'>
                <div className={`${props.error && 'border-red-1px '} ${isInputFocused ? "e-border-1px-indigo" : "e-border-1px-columbia-blue"} e-border-radius-8 position-relative`}>
                    <input id={props.id}
                        ref={inputRef}
                        value={props.value}
                        defaultValue={props.defaultValue}
                        maxLength={props.maxLength}
                        tabIndex={props.tabIndex}
                        autoFocus={props.autoFocus}
                        type={show ? "text" : "password"}
                        required={props.required}
                        readOnly={props.readOnly}
                        placeholder={props.placeholder}
                        className={`${styles.e_password_input} ${props.className} ps-3 pe-5 padding-12px-tb border-0 e-border-radius-8 e-bg-white e-font-14 e-text-raisin-black e-alt-font-noto-sans e-font-weight-400 line-height-22px`}
                        onWheel={() => document.activeElement.blur()}
                        onFocus={_handleFocus}
                        onBlur={_handleBlur}
                        onChange={_handleChange}
                        onKeyDown={(e) => keyPress(e)}
                        onClick={props.onClick} />

                    <img src={show ? hideIcon : showIcon}
                        draggable={false}
                        className='cursor-pointer position-absolute top-14px right-12px'
                        width={18}
                        alt='icon'
                        onClick={_handleShow} />

                </div>
                {/* error message */}
                {props.error &&
                    <p className="mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px pt-1">
                        {props.error}
                    </p>
                }
            </div>
        </div >
    )
}

CustomPasswordInput.defaultProps = {
    onKeyPress: () => { },
    className: null,
    error: "",
}

export default memo(CustomPasswordInput);