/*
 *   File : manage-download.js
 *   Author : https://evoqins.com
 *   Description : Manage download page
 *   Integrations : react-toastify,js-cookie
 *   Version : 1.0.0
*/

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

//Custom components
import { Icon } from '../../../Component/Icon';
import { CustomFileUpload, CustomSelectBox, CustomTextInput } from '../../../Component/FormElements';
import { TextArea } from '../../../Component/FormElements';
import { PrimaryButton, TextButton } from '../../../Component/Buttons';
import { APIService, AWSService } from '../../../Service';
import { Loader } from '../../../Component/Others';

const RESOURCE_TYPE = [
    // {
    //     value: 1,
    //     label: 'Video',
    //     file_type: 'VIDEO'
    // },
    {
        value: 2,
        label: 'Document',
        file_type: 'DOC'
    },
    {
        value: 3,
        label: 'PDF',
        file_type: 'PDF'
    },
    {
        value: 4,
        label: 'PPT',
        file_type: 'PPT'
    },
];

const MODULE_ERROR = {
    name: "",
    description: "",
    duration_in_text: "",
    download_url: "",
    file_type_id: "",
};

const ManageDownload = () => {

    const { state } = useLocation();
    const moduleRef = useRef([]);
    const navigate = useNavigate();

    const [adminType, setAdminType] = useState(Cookies.get("admin_type") === "1" ? "super_admin" : "country_admin");

    const [modules, setModules] = useState([]);
    const [loading, setLoading] = useState(true);
    const [createLoader, setCreateLoader] = useState(true);

    const [moduleUploadLoading, setModuleUploadLoading] = useState(modules.map(() => false));

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, []);

    useEffect(() => {
        if (state !== null) {
            if (state.type === "edit") {
                _getDownloadDetail();
            } else {
                setModules([{
                    name: "",
                    description: "",
                    download_url: null,
                    file_type_id: null,
                    duration_in_text: "",
                    errors: MODULE_ERROR,
                    id: 1
                }]); // setting default value
                setLoading(false);
            }

        } else {
            navigate("/manage-resource");
        }
        setCreateLoader(false);

    }, [state]);

    // handle Change
    const _handleChange = useMemo(() => (index, field, updated_value) => {
        const current_module = [...modules];

        if (field === "file_type_id") {
            const selected_resource_type = RESOURCE_TYPE.findIndex((type) => type.value === updated_value.value);
            let selected_module_type = updated_value;
            current_module[index] = {
                ...modules[index],
                [field]: RESOURCE_TYPE[selected_resource_type].value,
                module_type: selected_module_type,
                download_url: null,
                errors: {
                    ...modules[index].errors,
                    [field]: "",
                }
            };

        } else {
            current_module[index] = {
                ...modules[index],
                [field]: updated_value,

                errors: {
                    ...modules[index].errors,
                    [field]: "" // Clear error for the edited field
                }
            };
        }
        setModules(current_module);
    }, [modules]);


    // handle upload document
    const _handleUploadDocument = (index, field, url) => {

        setModuleUploadLoading(prev => {
            const new_state = [...prev];
            new_state[index] = { ...new_state[index], [field]: true };
            return new_state;
        });

        _getPresignedUrl(url, index, field);
    };

    // handle change resource
    const _handleChangeResource = (index, field) => {
        _handleChange(index, field, null);
    };

    // handle add more modules
    const _handleAddModule = () => {
        setModules(prev => [
            ...prev, {
                name: "",
                description: "",
                download_url: null,
                file_type_id: null,
                duration_in_text: "",
                errors: MODULE_ERROR,
                id: prev.length + 1
            }
        ]);
    };

    // handle delete each section
    const _handleDeleteModule = (index) => {
        const new_module = [...modules];
        new_module.splice(index, 1);
        setModules(new_module);
    };

    // validate the form
    const _validate = () => {
        let is_valid = true;

        // for error
        const new_section = modules.map((data) => {
            const error_object = {};

            if (data.name.trim().length === 0) {
                error_object.name = "Module name is required";
                is_valid = false;
            } else {
                error_object.name = "";
            }

            if (data.description.trim().length === 0) {
                error_object.description = "Description is required";
                is_valid = false;
            } else {
                error_object.description = "";
            }

            if (data.file_type_id === null) {
                error_object.file_type_id = "Select resource type";
                is_valid = false;
            } else {
                error_object.file_type_id = "";
            }

            if (data.file_type_id !== null) {
                if (data.download_url === null) {
                    error_object.download_url = "Resource is required";
                    is_valid = false;
                } else {
                    error_object.download_url = "";
                }
            }


            if (data.duration_in_text.trim().length === 0) {
                error_object.duration_in_text = "Duration is required";
                is_valid = false;
            } else {
                error_object.duration_in_text = "";
            }

            return { ...data, errors: error_object };
        });

        // focus error 
        if (state.type === "edit") {
            for (let i = 0; i < new_section.length; i++) {
                const section = new_section[i];
                if (section.errors && Object.values(section.errors).some(error => error.trim().length !== 0)) {
                    if (moduleRef.current[i]) {
                        moduleRef.current[i].scrollIntoView({ behavior: 'smooth', block: 'start', });
                        break;
                    }
                }
            }
        }

        setModules(new_section);
        return is_valid;
    };


    // handle submit
    const _handleSubmit = () => {
        if (_validate()) {
            setCreateLoader(true);

            if (state.type === "edit") {
                _updateDownload();
            } else {
                _addDownload();
            }
        } else {
            console.log("error");
        }
    };

    // handle back
    const _handleBack = (id) => {
        navigate("/manage-resource/course-detail", {
            state: {
                course_id: state.course_id,
                tab_id: id
            }
        });
    };

    // Common function to get module type
    const _getModuleType = (type) => {
        switch (type) {
            // case 'VIDEO':
            //     return RESOURCE_TYPE[0];
            case 'DOC':
                return RESOURCE_TYPE[0];
            case 'PDF':
                return RESOURCE_TYPE[1];
            default:
                return RESOURCE_TYPE[2];
        }
    };

    // get presigned url
    async function _getPresignedUrl(file, index = null, field = null) {
        let is_post = true;
        let url = 'general/file-upload';
        let data = {
            "file": file.name,
            "is_public": true
        };
        try {
            let response = await APIService(is_post, url, data);

            if (response.status_code === 200) {

                let aws_fields = response.data.upload_info.fields;
                let aws_url = response.data.upload_info.url;

                try {
                    let aws_response = await AWSService(aws_fields, file, aws_url);
                    if (aws_response.status === 'ok') {

                        const new_data = [...modules];
                        new_data[index][field] = response.data.view_info;
                        setModules(new_data);

                        const current_module = [...modules];
                        current_module[index].errors.download_url = ""; // Clear error for the 'url' field
                        setModules(current_module);

                        setModuleUploadLoading(prev => {
                            const new_state = [...prev];
                            new_state[index] = { ...new_state[index], [field]: false };
                            return new_state;
                        });

                    } else {
                        toast.dismiss();
                        toast.error(aws_response.message, {
                            type: "error",
                            className: "e-toast",
                        });
                    }
                } catch (err) {
                    console.log("error")
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        } catch (err) {
        }
    };

    // API - Add downloads
    const _addDownload = () => {
        let is_post = true;
        let url = adminType === "super_admin" ? 'course/add-downloads' : 'CA/course/add-downloads';
        let data = {
            "course_id": state.course_id,
            "downloads": modules
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                navigate("/manage-resource/course-detail", {
                    state: {
                        course_id: state.course_id,
                    }
                });

                toast.dismiss();
                toast.success("Download added successfully", {
                    type: "success",
                    className: "e-toast",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setCreateLoader(false);
        });
    };

    // API - update downloads
    const _updateDownload = () => {
        let is_post = true;
        let url = adminType === "super_admin" ? 'course/update-downloads' : 'CA/course/update-downloads';
        let data = {
            "course_id": state.course_id,
            "downloads": modules
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                navigate("/manage-resource/course-detail", {
                    state: {
                        course_id: state.course_id,
                        tab_id: 3
                    }
                });

                toast.dismiss();
                toast.success("Download updated successfully", {
                    type: "success",
                    className: "e-toast",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setCreateLoader(false);
        });
    };

    // API - Download detail
    const _getDownloadDetail = () => {

        let is_post = true;
        let url = adminType === "super_admin" ? 'course/detail' : 'CA/course/detail';
        let data = {
            "course_id": state.course_id
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                if (response.status_code === 200) {

                    let modules = response.data.downloads;

                    for (let index = 0; index < modules.length; index++) {
                        let module_type = modules[index].file_type;
                        let selected_module_type = _getModuleType(module_type);

                        modules[index].module_type = selected_module_type;
                    }

                    const updated_module = modules.map(data => ({
                        ...data,
                        errors: MODULE_ERROR,
                    }));

                    setModules(updated_module);
                    setLoading(false);
                } else {
                    navigate("/manage-resource");
                }
            } else {

            }
            setLoading(false);
        });
    };

    return (
        <section className='pb-5 '>
            <div className={`${adminType === "super_admin" ? 'e-bg-azureish-white' : 'e-bg-lavender-blush-light'} pt-3 padding-98px-bottom padding-52px-lr`}>
                <div className='e-display-flex'>
                    <Icon icon="arrow-left"
                        className='cursor-pointer'
                        size="24px"
                        onPress={() => _handleBack(3)} />
                    <h1 className='ps-1 mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                        {state?.type === "edit" ? `Edit download` : `Add new download`}
                    </h1>
                </div>
            </div>

            <div className='margin-neg-66px-top padding-52px-lr'>
                {loading ?
                    // loader 
                    <Loader />
                    :
                    <div className='e-bg-white e-admin-box-shadow p-4 e-border-radius-16 '>
                        <div className='d-flex gap-0-8 justify-content-space-between'>
                            <h3 className='mb-0 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px'>
                                {state.type === "edit" ? `Download details` : `Add download details`}
                            </h3>

                            <PrimaryButton name={state.type === "edit" ? "Save & Update Downloads" : "Save & Add Downloads"}
                                className={`${createLoader ? 'padding-6px-tb' : 'py-2'} w-236px e-border-radius-16 e-font-16`}
                                status={createLoader}
                                onPress={_handleSubmit} />
                        </div>

                        {/* Add sections */}
                        {modules.map((module, key) => {
                            return (
                                <div key={key} ref={error => moduleRef.current[key] = error} className='row pb-2 '>
                                    <div className='col-xl-9 col-lg-9 col-md-10 col-12'>

                                        <div className={`${key === 0 && `h-24px`} text-end`}>
                                            {modules.length > 1 &&
                                                <span className='mb-2 e-text-lust e-font-16 e-alt-font-noto-sans e-font-weight-600 line-height-22px cursor-pointer text-decoration-hover-underline'
                                                    onClick={() => _handleDeleteModule(key)} >
                                                    Delete
                                                </span>
                                            }
                                        </div>

                                        {/* Module name */}
                                        <div className='mt-2 e-bg-ghost-white p-3 e-border-radius-16 transition'>
                                            <CustomTextInput label="Resource name"
                                                placeholder="Successful negotiation essential strategies & skills"
                                                type="text"
                                                require
                                                value={module.name}
                                                error={module.errors.name}
                                                className="w-100 padding-10px-tb e-border-radius-8"
                                                labelStyle="e-font-weight-500 e-text-raisin-black"
                                                borderStyle="e-border-1px-platinum-light"
                                                handleChange={(e) => _handleChange(key, 'name', e)} />

                                            {/* description */}
                                            <TextArea label="Resource description"
                                                placeholder="Enter your text..."
                                                type="text"
                                                rows="3"
                                                value={module.description}
                                                error={module.errors.description}
                                                require
                                                className="w-100 px-3 padding-10px-tb"
                                                handleChange={(e) => _handleChange(key, 'description', e)} />

                                            {/* Resource type */}
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <CustomSelectBox label="Resource type"
                                                        value={module.module_type}
                                                        options={RESOURCE_TYPE}
                                                        error={module.errors.file_type_id}
                                                        className="e-section-select e-bg-white e-border-radius-8"
                                                        isSearchable={false}
                                                        selectChange={(id) => {
                                                            _handleChange(key, 'file_type_id', id)
                                                        }} />
                                                </div>

                                                {/* Duration */}
                                                <div className='col-6'>
                                                    <CustomTextInput label="Duration"
                                                        placeholder="30 sec"
                                                        type="text"
                                                        require
                                                        value={module.duration_in_text}
                                                        error={module.errors.duration_in_text}
                                                        className="w-100 padding-10px-tb e-border-radius-8"
                                                        labelStyle="e-font-weight-500 e-text-raisin-black"
                                                        borderStyle="e-border-1px-platinum-light "
                                                        handleChange={(e) => _handleChange(key, 'duration_in_text', e)} />
                                                </div>
                                            </div>

                                            <>
                                                {
                                                    module.module_type?.label === "Video" ?
                                                        <>
                                                            {/* upload video */}
                                                            <CustomFileUpload label="Upload video"
                                                                placeholder="Drop your video here"
                                                                file={module.download_url}
                                                                fileType={["MP4", "MOV",]}
                                                                require
                                                                type={2}
                                                                support="MP4"
                                                                loader={moduleUploadLoading[key]?.download_url}
                                                                error={module.errors.download_url}
                                                                handleChange={(e) => _handleUploadDocument(key, 'download_url', e)} />

                                                            {module.download_url !== null &&
                                                                <div className='margin-neg-8px-top'>
                                                                    <TextButton name="Change video"
                                                                        onPress={() => _handleChangeResource(key, 'download_url')} />
                                                                </div>
                                                            }
                                                        </>
                                                        :
                                                        module.module_type?.label === "Document" ?
                                                            <>
                                                                {/* upload document */}
                                                                <CustomFileUpload label="Upload document"
                                                                    placeholder="Drop your document here"
                                                                    file={module.download_url}
                                                                    fileType={["DOC", "DOCX"]}
                                                                    require
                                                                    type={3}
                                                                    support="DOC,DOCX"
                                                                    loader={moduleUploadLoading[key]?.download_url}
                                                                    error={module.errors.download_url}
                                                                    handleChange={(e) => _handleUploadDocument(key, 'download_url', e)} />

                                                                {module.download_url !== null &&
                                                                    <div className='margin-neg-8px-top'>
                                                                        <TextButton name="Change document"
                                                                            onPress={() => _handleChangeResource(key, 'download_url')} />
                                                                    </div>
                                                                }
                                                            </>
                                                            :
                                                            module.module_type?.label === "PDF" ?
                                                                <>
                                                                    {/* upload document */}
                                                                    <CustomFileUpload label="Upload PDF"
                                                                        placeholder="Drop your PDF here"
                                                                        file={module.download_url}
                                                                        fileType={["PDF"]}
                                                                        require
                                                                        type={3}
                                                                        support="PDF"
                                                                        loader={moduleUploadLoading[key]?.download_url}
                                                                        error={module.errors.download_url}
                                                                        handleChange={(e) => _handleUploadDocument(key, 'download_url', e)} />

                                                                    {module.download_url !== null &&
                                                                        <div className='margin-neg-8px-top'>
                                                                            <TextButton name="Change document"
                                                                                onPress={() => _handleChangeResource(key, 'download_url')} />
                                                                        </div>
                                                                    }
                                                                </>
                                                                :
                                                                module.module_type?.label === "PPT" ?
                                                                    <>
                                                                        {/* upload document */}
                                                                        <CustomFileUpload label="Upload PPT"
                                                                            placeholder="Drop your PPT here"
                                                                            file={module.download_url}
                                                                            fileType={["PPT"]}
                                                                            require
                                                                            type={3}
                                                                            support="PPT"
                                                                            loader={moduleUploadLoading[key]?.download_url}
                                                                            error={module.errors.download_url}
                                                                            handleChange={(e) => _handleUploadDocument(key, 'download_url', e)} />

                                                                        {module.download_url !== null &&
                                                                            <div className='margin-neg-8px-top'>
                                                                                <TextButton name="Change document"
                                                                                    onPress={() => _handleChangeResource(key, 'download_url')} />
                                                                            </div>
                                                                        }
                                                                    </>
                                                                    :
                                                                    null
                                                }
                                            </>
                                        </div>
                                    </div>

                                    {/* add more module button */}
                                    <div className='col-xl-3 col-lg-3 col-md-10 col-12 my-auto text-xl-center text-lg-center text-end pt-xl-0 pt-lg-0 pt-2'>
                                        {modules.length === key + 1 &&
                                            < p className='mb-0 e-text-indigo e-font-16 e-alt-font-noto-sans e-font-weight-600 line-height-22px cursor-pointer'
                                                onClick={_handleAddModule} >
                                                + <span className='cursor-pointer text-decoration-hover-underline '>Add new resource</span>
                                            </p>
                                        }
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                }
            </div>


        </section>
    )
}

export default ManageDownload;
