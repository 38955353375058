/*
 *   File : date-picker.js
 *   Author : https://evoqins.com
 *   Description : date picker component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';

const CustomDatePicker = (props) => {

    const default_month = new Date();
    const [selectedDate, setSelectedDate] = useState(null);
    const [convertedMinDate, setConvertedMinDate] = useState(null);

    useEffect(() => {
        if (props.minDate && typeof props.minDate === "string") {
            let converted_min_date = convertDate(props.minDate);
            setConvertedMinDate(converted_min_date);
        }
    }, [props.minDate]);

    useEffect(() => {
        if (props.selected !== null) {
            setSelectedDate(new Date(props.selected));
        } else {
            setSelectedDate(props.selected);
        }
    }, [props.selected]);

    // Function to disable past days
    const _handleDisablePastDays = (day) => {
        if (props.type === "alarm") {
            const midnight = new Date();
            midnight.setHours(0, 0, 0, 0);  //jotun alarm cannot select today

            if (props.minDate && day.getTime() < props.minDate.getTime()) {
                return true;
            }

            return day.getTime() <= midnight.getTime();

        } else if (typeof props.minDate === "string" && convertedMinDate instanceof Date) {

            return day.getTime() < convertedMinDate.getTime();  // Disable dates before minDate

        } else if (props.minDate !== undefined && props.minDate instanceof Date) {

            return day.getTime() < props.minDate.getTime();  // Disable dates before minDate

        } else {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            day.setHours(0, 0, 0, 0);
            return day.getTime() < today.getTime();  // disable past days
        }
    };

    const convertDate = (input_date) => {
        // Parse the input date string to a Date object
        const date_parts = input_date.split(" "); // Assuming "DD MM YYYY" format
        const formatted_date = new Date(`${date_parts[2]}-${date_parts[1]}-${date_parts[0]}`); // Reformat to 'YYYY-MM-DD'

        return formatted_date; // Return a Date object
    };

    return (
        <DayPicker mode="single"
            selected={selectedDate}
            showOutsideDays
            className={`${props.className} ${props.type === "modal" ? "" : "position-absolute"} e-day-picker w-358px e-bg-white e-border-radius-16 z-index-99`}
            format="yyyy-MM-dd"
            defaultMonth={default_month}
            fromMonth={default_month}
            disabled={_handleDisablePastDays}
            selectedDays={props.selected}
            onDayClick={props.onDayClick}
            onSelect={props.onSelect} />
    )
}

export default memo(CustomDatePicker);