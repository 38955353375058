/*
 *   File : offer-listing.js
 *   Author : https://evoqins.com
 *   Description : Offer listing page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';

// Custom components
import { PrimaryButton } from '../../../Component/Buttons';
import { OfferCard } from '../../../Component/Cards';
import { DataTableComponent } from '../../../Component/DataTable';
import { CustomSearchInput, CustomSelectBox } from '../../../Component/FormElements';
import { AddOfferModal, ConfirmDeleteModal, ManageRedemptionModal, RejectRedemptionModal } from '../../../Component/Modal';
import { Icon } from '../../../Component/Icon';
import { APIService } from '../../../Service';
import { Pagination } from '../../../Component/pagination';
import { Loader } from '../../../Component/Others';
import { EmptyScreen } from '../../../Component/EmptyComponent';

const CATEGORY_TAB = [
    {
        id: 1,
        name: "Redeem offers"
    },
    {
        id: 2,
        name: "Offer redeem requests"
    },
];

const WEEK_OPTIONS = [
    {
        value: 1,
        label: 'All',
    },
    {
        value: 2,
        label: 'Last Week'
    },
    {
        value: 3,
        label: 'Last Month'
    },
];

const ITEMS_PER_PAGE = 12;

const OfferListing = () => {

    const navigate = useNavigate();
    const { state } = useLocation();

    const [tabIndex, setTabIndex] = useState(0);
    const [searchValue, setSearchValue] = useState(null);
    const [toggleFilter, setToggleFilter] = useState(false);
    const [selected, setSelected] = useState(WEEK_OPTIONS[0]);

    const [couponList, setCouponList] = useState({});

    const [totalPageCount, setTotalPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [editData, setEditData] = useState({});
    const [offerModal, setOfferModal] = useState(null);
    const [loading, setLoading] = useState(true);

    const [requestList, setRequestList] = useState({});
    const [tableCurrentPage, setTableCurrentPage] = useState(1);
    const [requestLoading, setRequestLoading] = useState(true);

    const [approveSuccess, setApproveSuccess] = useState(false);
    const [rejectId, setRejectId] = useState(null);
    const [rejectResponse, setRejectResponse] = useState(false);
    const [selectedError, setSelectedError] = useState("");
    const [messageError, setMessageError] = useState("");
    const [rejectLoader, setRejectLoader] = useState(true);

    const [deleteId, setDeleteId] = useState(null);
    const [couponDeleteResponse, setCouponDeleteResponse] = useState(false);
    const [acceptLoading, setAcceptLoading] = useState({});

    const [couponReasonData, setCouponReasonData] = useState([]);
    const [reasonLoading, setReasonLoading] = useState(true);

    useEffect(() => {
        const opened_modal = document.querySelector('.modal.show');
        if (opened_modal) {
            const modal = Modal.getInstance(opened_modal);
            modal.hide();

            setRejectId(null);
            setOfferModal(null);
            setDeleteId(null);
            setApproveSuccess(false);
        }
    }, [state]);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
        setRejectLoader(false);
    }, []);

    useEffect(() => {
        _getCouponList();
    }, [currentPage, tabIndex]);

    useEffect(() => {
        if (tabIndex === 2) {
            _getRequestList();
            setRejectId(null);
        }
    }, [tabIndex, searchValue, tableCurrentPage]);

    useEffect(() => {
        if (state !== null) {
            setTabIndex(state);
        } else {
            setTabIndex(1);
        }
        setRejectId(null);
        setOfferModal(null);
        setDeleteId(null);
        setApproveSuccess(false);
    }, [state]);

    useEffect(() => {
        if (offerModal !== null) {
            const offer_modal = new Modal(document.getElementById('add-offer-modal'));
            offer_modal.show();
        }
    }, [offerModal]);

    useEffect(() => {
        if (approveSuccess === true) {
            const accept_modal = new Modal(document.getElementById('approve-redemption-modal'));
            accept_modal.show();
        }
    }, [approveSuccess]);

    useEffect(() => {
        if (rejectId !== null) {
            const reject_modal = new Modal(document.getElementById('redemption-reject-modal'));
            reject_modal.show();
        }
    }, [rejectId]);

    useEffect(() => {
        if (deleteId !== null) {
            const delete_modal = new Modal(document.getElementById('confirm-delete-modal'));
            delete_modal.show();
        }
    }, [deleteId]);

    // handle tab change 
    const _handleTabChange = (id) => {
        navigate("/redeem-offer", {
            state: id
        });
        setTableCurrentPage(1);
    };

    // handle add new offer modal
    const _handleAddNewOffer = () => {
        setOfferModal(true);
        setEditData({});
    }

    // handle edit offer modal
    const _handleEditOffer = (data, type) => {
        setOfferModal(false);
        setEditData({ data, type });
    };

    // handle delete offer 
    const _handleDeleteCoupon = (id) => {
        setCouponDeleteResponse(false);
        setDeleteId(id);
    };

    //handle pagination
    const _handlePagination = (new_start_limit) => {
        const selected_page = Math.floor(new_start_limit / ITEMS_PER_PAGE) + 1;
        setCurrentPage(selected_page);

        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
    };

    // handle view history 
    const _handleRedemptionHistory = () => {
        navigate("/redemption-history");
    }

    //handle accept request
    const _handleAccept = (id, is_approve) => {
        setAcceptLoading(prev => ({
            ...prev,
            [id]: true
        }));

        _manageRequest(id, is_approve);
    };

    //handle reject request
    const _handleReject = (id) => {
        setRejectId(id);
        _couponRejectionReason();  //API - get reasons
    };

    const TABLE_COLUMNS = [
        {
            name: 'Emp. ID',
            selector: row => row.employee_id,
            width: "80px"
        },
        {
            name: 'Name',
            // selector: row => row.employee_name,
            cell: (row) =>
                <span className='text-decoration-hover-underline cursor-pointer'
                    onClick={() => navigate("/sales-specialist/detail")}>
                    {row.employee_name}
                </span>,
            width: "150px"

        },
        {
            name: 'Requested on',
            selector: row => row.created,
            width: "150px"
        },
        {
            name: 'Redeem point',
            selector: row => row.redemption_points,
            width: "150px"
        },
        {
            name: 'Coupon name',
            selector: row => row.coupon_title,
            width: "150px"

        },
        {
            name: 'Store name',
            selector: row => row.store_name,
            width: "280px"
        },
        {
            name: '',
            // selector: row => row.store,
            cell: (row) =>
                <div className="d-flex gap-0-8">
                    <PrimaryButton name="Accept"
                        status={acceptLoading[row.redemption_request_id]}
                        className={`${acceptLoading[row.redemption_request_id] ? 'py-1' : 'padding-6px-tb'} w-70px padding-14px-lr e-accept`}
                        onPress={() => _handleAccept(row.redemption_request_id, true)} />
                    <PrimaryButton name="Reject"
                        className="padding-6px-tb padding-14px-lr e-reject"
                        onPress={() => _handleReject(row.redemption_request_id, false)} />
                </div>,

            width: "200px"
        }
    ];

    // handle table page change 
    const _handlePageChange = (page_num) => {
        setTableCurrentPage(page_num);
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    };

    // handle reject submit 
    const _handleRejectSubmit = (data) => {
        console.log("data", data)

        let is_valid = true;

        if (data.selected === null) {
            setSelectedError("Select an reason");
            is_valid = false;
        }

        if (data.selected !== null && data.selected.value === null) {
            if (data.message.trim().length === 0) {
                setMessageError("Other reason is required");
                is_valid = false;

            }
        }

        console.log("is_valid:", is_valid)

        if (is_valid === true) {
            setRejectLoader(true);
            _manageRequest(rejectId, false, data);
        }
    };

    // API - Coupon list 
    const _getCouponList = () => {
        let is_post = true;
        let url = 'CA/coupon/list';
        let data = {
            "page_num": currentPage
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                setCouponList(response.data);
                setTotalPageCount(Math.ceil(response.data.total_items / ITEMS_PER_PAGE));
            } else {
            }
            setLoading(false);
        });
    };

    // API - Pending request list 
    const _getRequestList = () => {
        let is_post = true;
        let url = 'CA/coupon/pending-requests';
        let data = {
            "page_num": tableCurrentPage,
            "query": searchValue !== null && searchValue.length !== 0 ? searchValue : null
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setRequestList(response.data);
            } else {
            }
            setRequestLoading(false);
        });
    };

    // API - Accept and reject coupon request
    const _manageRequest = (request_id, is_approve, reason) => {
        let is_post = true;
        let url = 'CA/coupon/reject-requests';
        let data = {
            "request_id": request_id,
            "is_approve": is_approve,
            "reason_id": is_approve === true ? null : reason.selected.value,
            "reason": is_approve === true ? null : reason.message
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                if (is_approve === true) {
                    setApproveSuccess(true);
                } else {
                    setRejectResponse(true);
                }
            } else {

                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
                setApproveSuccess(false);
                setRejectResponse(false);
            }
            setRejectLoader(false);
            setAcceptLoading({});
        });
    };

    // API - Delete coupon
    const _deleteCoupon = () => {
        let is_post = true;
        let url = 'CA/coupon/delete';
        let data = {
            "coupon_id": deleteId
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCouponDeleteResponse(true);
            } else {
                setCouponDeleteResponse(false);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    // API - Get coupon rejection reason list
    const _couponRejectionReason = () => {
        let is_post = false;
        let url = 'CA/coupon/rejection-reasons';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCouponReasonData(response.data);
            } else { }
            setReasonLoading(false);
        });
    };

    return (
        <section className='pb-5 '>
            <div className='e-bg-lavender-blush-light pt-3 padding-52px-lr'>
                <h1 className='mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-raisin-black e-font-weight-600'>
                    Manage redeem offers
                </h1>

                {/* category tab */}
                <div className='d-flex flex-wrap padding-40px-top padding-20px-bottom justify-content-space-between align-items-center'>
                    <div className='d-flex gap-0-16 overflow-auto'>
                        {CATEGORY_TAB.map((data, key) => (
                            <div key={key} className={`${tabIndex === data.id ? 'e-bg-lust e-text-white border-transparent' : 'e-text-rich-black e-border-1px-cool-grey'} transition e-border-radius-24 cursor-pointer`}
                                onClick={() => _handleTabChange(data.id)} >
                                <p className='mb-0 px-4 padding-12px-tb e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-14px w-max-content'>
                                    {data.name}
                                </p>
                            </div>
                        ))}
                    </div>
                    {tabIndex === 1 &&
                        <PrimaryButton name="Add new offers"
                            className="me-3 e-border-radius-16 padding-14px-tb line-height-12px e-font-16 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-2 mt-2"
                            onPress={_handleAddNewOffer} />
                    }
                </div>
            </div>

            <div className='padding-52px-lr'>
                {tabIndex === 1 ?
                    <>
                        {loading ?
                            // loader 
                            <Loader />
                            :
                            <>
                                {couponList.data.length !== 0 ?
                                    <>
                                        <div className='row pt-4 mt-2 row-gap-3'>
                                            {couponList.data.map((data, key) => (
                                                <div key={key} className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12'>
                                                    <div className="d-flex h-100">
                                                        <OfferCard data={data}
                                                            handleEdit={() => _handleEditOffer(data, "edit")}
                                                            handleDelete={() => _handleDeleteCoupon(data.coupon_id)} />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {totalPageCount > 1 &&
                                            <div className="d-flex justify-content-center e-gap-0-16 mt-4 pt-3">
                                                <Pagination itemsPerPage={ITEMS_PER_PAGE}
                                                    totalPage={totalPageCount}
                                                    onChange={_handlePagination}
                                                    showFirstLastPages={true} />
                                            </div>
                                        }
                                    </>
                                    :
                                    // empty screen
                                    <div className='h-100vh pt-5 mt-5'>
                                        <EmptyScreen message="No requests found" />
                                    </div>
                                }
                            </>
                        }
                    </>
                    :
                    tabIndex === 2 ?
                        <div className='e-bg-white e-admin-box-shadow py-4 e-border-radius-16 mt-4'>
                            <div className='row px-4'>
                                <div className='col-4'>
                                    <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-600 line-height-28px'>
                                        All redeem request
                                    </h4>
                                </div>
                                <div className='col-8 text-end'>
                                    <div className='row '>
                                        <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 pb-xl-0 pb-lg-0 pb-md-0 pb-2'>
                                            <PrimaryButton name="View history"
                                                className="px-3 padding-11px-tb e-font-weight-400 e-bg-alice-blue e-text-raisin-black e-border-radius-8"
                                                onPress={_handleRedemptionHistory} />
                                        </div>
                                        <div className='col-xl-8 col-lg-8 col-md-8 col-sm-11 pe-2 ps-2'>
                                            <div className='position-relative'>
                                                <CustomSearchInput placeholder="Search by reward name or user name"
                                                    type="text"
                                                    require
                                                    search={true}
                                                    value={searchValue}
                                                    className="w-100 padding-10px-tb"
                                                    labelStyle="e-font-weight-500"
                                                    handleChange={(e) => setSearchValue(e)} />
                                                {searchValue !== null && searchValue.length !== 0 &&
                                                    <Icon icon="close-icon"
                                                        className='position-absolute top-12px right-12px cursor-pointer'
                                                        size="20px"
                                                        onPress={() => setSearchValue(null)} />
                                                }
                                            </div>
                                        </div>
                                        {/* <div className='col-xl-1 col-lg-1 col-md-1 col-sm-2 ps-2 my-auto'>
                                            <img src={toggleFilter ? require("../../../Assets/Image/filter-icon-white.svg").default : require("../../../Assets/Image/filter-icon.svg").default}
                                                draggable={false}
                                                className={`${toggleFilter ? `e-bg-indigo` : ``} transition e-border-1px-anti-flash-white  e-border-radius-8 p-2 cursor-pointer`}
                                                alt='filter'
                                                onClick={() => setToggleFilter(!toggleFilter)} />
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            {/* filter */}
                            {toggleFilter &&
                                <div className='e-bg-anti-flash-white-dark py-3 px-4 mt-3'>
                                    <div className='row'>
                                        <div className='col-2'>
                                            <CustomSelectBox label="Category"
                                                value={selected}
                                                options={WEEK_OPTIONS}
                                                type={2}
                                                className="e-bg-white e-border-radius-8 e-table-select"
                                                isSearchable={false}
                                                selectChange={(value) => { setSelected(value); }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <>
                                {requestLoading ?
                                    // loader 
                                    <Loader />
                                    :
                                    <>
                                        {/* Redeem request data table */}
                                        {requestList.data.length !== 0 ?
                                            <div className='e-table pt-2'>
                                                <DataTableComponent columns={TABLE_COLUMNS}
                                                    data={requestList.data}
                                                    pagination={true}
                                                    paginationServer={true}
                                                    paginationTotalRows={requestList.total_items}
                                                    onChangePage={_handlePageChange} />
                                            </div>
                                            :
                                            // empty screen
                                            <div className='h-100vh pt-5 mt-5'>
                                                <EmptyScreen message="No requests found" />
                                            </div>
                                        }
                                    </>
                                }
                            </>

                        </div>
                        :
                        null
                }
            </div>

            {/* add new offer modal */}
            {offerModal !== null &&
                <AddOfferModal offerData={editData}
                    handleClose={() => setOfferModal(null)}
                    success={() => {
                        setCurrentPage(currentPage);
                        _getCouponList();
                    }} />
            }

            {/* confirm delete modal */}
            {deleteId !== null &&
                <ConfirmDeleteModal label="redeem offer"
                    deleteId={deleteId}
                    response={couponDeleteResponse}
                    delete={() => _deleteCoupon()}
                    handleClose={() => setDeleteId(null)}
                    success={() => {
                        setLoading(true);
                        _getCouponList();
                    }} />
            }

            {/* approve redemption modal */}
            {approveSuccess === true &&
                <ManageRedemptionModal
                    type={1}
                    description="The reward redemption has been successful."
                    success={() => {
                        setRequestLoading(true);
                        _getRequestList();
                        setApproveSuccess(false);
                    }} />
            }

            {/* reject redemption reest modal */}
            {rejectId !== null &&
                <RejectRedemptionModal response={rejectResponse}
                    error={selectedError}
                    messageError={messageError}
                    loading={rejectLoader}
                    reasonLoader={reasonLoading}
                    reasonData={couponReasonData}
                    handleReject={_handleRejectSubmit}
                    success={() => {
                        setRequestLoading(true);
                        _getRequestList();
                        setRejectResponse(false);
                    }}
                    handleClose={() => setRejectId(null)} />
            }
        </section>
    )
}

export default OfferListing;