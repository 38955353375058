/*
 *   File : dashboard.js
 *   Author : https://evoqins.com
 *   Description : Country admin Home page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Custom Components
import { StatisticCard } from '../../../Component/Cards';
import { DataTableComponent } from '../../../Component/DataTable';
import { CustomSelectBox } from '../../../Component/FormElements';
import { NormalBarChart, DoughnutChart } from '../../../Component/Chart';
import { Icon } from '../../../Component/Icon';
import { APIService } from '../../../Service';
import { Loader } from '../../../Component/Others';

//Custom style
import styles from "../../../Style/Component/dashboard.module.scss";

const DASHBOARD_FILTER = [
    {
        value: 1,
        label: 'last week',
    },
    {
        value: 2,
        label: 'last month'
    },
    {
        value: 3,
        label: 'last year'
    },
    {
        value: 4,
        label: 'last 5 year'
    },
];
const TOP_SELLING_FILTER = [
    {
        value: 1,
        label: 'By shop',
    },
    {
        value: 2,
        label: 'By sales specialists'
    }
];

const REGISTRATION_FILTER = [
    {
        value: 1,
        label: 'Weekly',
    },
    {
        value: 2,
        label: 'Monthly'
    },
    {
        value: 3,
        label: 'Yearly'
    },
];

const Dashboard = () => {

    const navigate = useNavigate();
    const [selectedDashboard, setSelectedDashboard] = useState(DASHBOARD_FILTER[2]);
    const [selectedTopSelling, setSelectedTopSelling] = useState(TOP_SELLING_FILTER[0]);
    const [selectedRegistration, setSelectedRegistration] = useState(REGISTRATION_FILTER[1]);

    const [dashboardData, setDashboardData] = useState({});
    const [loading, setLoading] = useState(true);

    const [topSellingData, setTopSellingData] = useState([]);
    const [topSellingLoading, setTopSellingLoading] = useState(true);

    const [userRegistrationData, setUserRegistrationData] = useState({});
    const [registrationLoading, setRegistrationLoading] = useState(true);

    const [topSellingProductData, setTopSellingProductData] = useState({});
    const [sellingProductLoading, setSellingProductLoading] = useState(true);

    const [educationResourceData, setEducationResourceData] = useState({});
    const [resourceLoading, setResourceLoading] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
        _getEducationResource();
        _getTopSellingProduct();
    }, []);

    useEffect(() => {
        _getDashboardData();
    }, [selectedDashboard]);

    useEffect(() => {
        _getTopSellingData();
    }, [selectedTopSelling]);

    useEffect(() => {
        _getUserRegistrationData();
    }, [selectedRegistration]);

    // handle filter dashboard
    const _handleSortDashboard = (value) => {
        setSelectedDashboard(value);
        setLoading(true);
    };

    // handle sort top selling table
    const _handleSortTopSelling = (value) => {
        setSelectedTopSelling(value);
        setTopSellingLoading(true);
    };

    // handle sort user registration data 
    const _handleSortRegistration = (value) => {
        setSelectedRegistration(value);
        setRegistrationLoading(true);
    };

    // handle navigate to detail page
    const _handleNavigate = () => {
        navigate("sales-specialist/detail");
    };

    // handle navigate to manage resource 
    const _handleNavigateResource = (id) => {
        navigate("manage-resource", {
            state: id
        })
    };

    // Top selling data table 
    const TABLE_COLUMNS = [
        {
            name: 'Username',
            selector: row => row.username,
            width: "260px"
        },
        {
            name: 'Total earning',
            selector: row => row.Total_earning,
        },
        {
            name: 'Points',
            selector: row => row.points,
        },

    ];

    // API - Dash board data
    const _getDashboardData = () => {
        let is_post = true;
        let url = 'CA/dashboard/get';
        let data = {
            "filter": selectedDashboard.value
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setDashboardData(response.data);
            } else {
            }
            setLoading(false);
        });
    };

    // API - Dash board data
    const _getTopSellingData = () => {
        let is_post = true;
        let url = 'CA/dashboard/get-top-selling';
        let data = {
            "filter": selectedTopSelling.value
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setTopSellingData(response.data);
            } else {
            }
            setTopSellingLoading(false);
        });
    };

    // API - Get user registrations
    const _getUserRegistrationData = () => {
        let is_post = true;
        let url = 'CA/dashboard/user-registration-statistic';
        let data = {
            "filter": selectedRegistration.value
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setUserRegistrationData(response.data);
            } else {
            }
            setRegistrationLoading(false);
        });
    };

    // API - Get top selling products data
    const _getTopSellingProduct = () => {
        let is_post = false;
        let url = 'CA/dashboard/get-top-selling-product';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setTopSellingProductData(response.data);
            } else {
            }
            setSellingProductLoading(false);
        });
    };

    // API - Get education resource data
    const _getEducationResource = () => {
        let is_post = false;
        let url = 'CA/dashboard/education-resource';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setEducationResourceData(response.data);
            } else {
            }
            setResourceLoading(false);
        });
    };

    return (
        <section className='pb-5'>
            <div className='e-bg-lavender-blush-light pt-3 padding-98px-bottom padding-52px-lr'>
                <div className='d-flex justify-content-space-between'>
                    <h1 className='mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                        Dashboard
                    </h1>
                    <div>
                        <CustomSelectBox value={selectedDashboard}
                            options={DASHBOARD_FILTER}
                            type={1}
                            className="e-header-select-box"
                            selectChange={(value) => _handleSortDashboard(value)}
                            isSearchable={false} />
                    </div>
                </div>
            </div>
            <div className='padding-52px-lr margin-neg-66px-top'>

                {/* Statistic Card  */}
                <div className='row row-gap-3 row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-2 row-cols-2'>
                    <div className='col pe-1'>
                        <StatisticCard icon={require("../../../Assets/Image/CountryAdmin/total-sales.svg").default}
                            loader={loading}
                            quantity={dashboardData.total_sale}
                            title="Total sales"
                            className="e-box-shadow-business-stats e-filter-none" />
                    </div>
                    <div className='col pe-1'>
                        <StatisticCard icon={require("../../../Assets/Image/CountryAdmin/premium-sales.svg").default}
                            loader={loading}
                            quantity={dashboardData.total_premium_sale}
                            title="Premium sales"
                            className="e-box-shadow-business-stats e-filter-none" />
                    </div>
                    <div className='col pe-1'>
                        <StatisticCard icon={require("../../../Assets/Image/CountryAdmin/sold.svg").default}
                            loader={loading}
                            quantity={dashboardData.total_unit_sold}
                            title="Product units sold"
                            className="e-box-shadow-business-stats e-filter-none" />
                    </div>
                </div>

                <div className='row padding-32px-top'>
                    {/* Top Sales Specialists data table */}
                    <div className='col-xl-6 col-lg-6 col-md-6 col-12 pe-1 '>
                        <div className='d-flex flex-column h-100'>
                            <div className='flex-grow-1 e-bg-white e-leader-board-box-shadow pt-4 padding-32px-bottom e-border-radius-16'>
                                <div className='row px-4'>
                                    <div className='col-xl-8 col-lg-6 col-md-7 col-sm-8 col-6'>
                                        <h4 className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                                            Top Sales Specialists
                                        </h4>
                                    </div>
                                    <div className='col-xl-4 col-lg-6 col-md-5 col-sm-4 col-6'>
                                        <CustomSelectBox value={selectedTopSelling}
                                            options={TOP_SELLING_FILTER}
                                            type={1}
                                            isSearchable={false}
                                            selectChange={(value) => _handleSortTopSelling(value)} />
                                    </div>
                                </div>
                                {topSellingLoading ?
                                    <Loader className="h-100" /> ///loader
                                    :
                                    // Top Sales Specialists data table 
                                    <div className='pt-4 e-leader-board-table'>
                                        <DataTableComponent columns={TABLE_COLUMNS}
                                            data={topSellingData}
                                            rowClick={() => _handleNavigate()} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    {/* User registrations chart */}
                    <div className='col-xl-6 col-lg-6 col-md-6 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-4'>
                        <div className='d-flex flex-column h-100'>
                            <div className='flex-grow-1 e-bg-white e-leader-board-box-shadow p-4 e-border-radius-16 h-410px'>
                                <div className='d-flex justify-content-space-between flex-wrap align-items-center'>
                                    <h4 className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                                        User registrations
                                    </h4>
                                    <CustomSelectBox value={selectedRegistration}
                                        options={REGISTRATION_FILTER}
                                        type={1}
                                        className="e-reg-select-box"
                                        isSearchable={false}
                                        selectChange={(value) => _handleSortRegistration(value)} />
                                </div>

                                {registrationLoading ?
                                    <Loader className="h-100" /> ///loader
                                    :
                                    <div className='padding-36px-top'>
                                        <NormalBarChart label={userRegistrationData.label}
                                            type={2}
                                            data={userRegistrationData.data} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row padding-32px-top '>
                    {/* Top selling products Doughnut Chart */}
                    <div className='col-xl-6 col-lg-6 col-md-12 col-12 pe-1'>
                        <div className='d-flex flex-column h-100'>
                            <div className={`${sellingProductLoading ? '' : 'overflow-auto'} e-box-shadow-business-stats e-chart-scroll e-bg-azureish-white px-4 padding-30px-top padding-58px-bottom e-border-radius-16 flex-grow-1`}>
                                <h4 className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                                    Top selling products
                                </h4>
                                {sellingProductLoading ?
                                    <Loader className="h-100" /> ///loader
                                    :
                                    <div className='row padding-42px-top'>
                                        <div className='col-7 position-relative'>
                                            <div className='w-max-content'>
                                                <DoughnutChart label={topSellingProductData.label}
                                                    data={topSellingProductData.data}
                                                    totalProduct={topSellingProductData.total_product} />
                                            </div>
                                        </div>

                                        <div className={`${styles.e_interior_paint} col-5 pe-0`}>
                                            <div className={`${styles.e_top_selling_scroll} h-210 overflow-auto`}>
                                                <div className='pb-4'>
                                                    <ul className='ps-3  mb-2 '>
                                                        <li className={`${styles.first} w-max-content e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-600 line-height-16px`}>
                                                            {topSellingProductData.label[0]}
                                                        </li>
                                                    </ul>
                                                    <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-16 e-font-weight-600 line-height-28px'>
                                                        {topSellingProductData.data[0]} <span className='e-font-14 e-font-weight-400'>Units</span>
                                                    </p>
                                                </div>
                                                <div className='pb-4'>
                                                    <ul className='ps-3  mb-2 '>
                                                        <li className={`${styles.second} w-max-content e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-600 line-height-16px`}>
                                                            {topSellingProductData.label[1]}
                                                        </li>
                                                    </ul>
                                                    <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-16 e-font-weight-600 line-height-28px'>
                                                        {topSellingProductData.data[1]} <span className='e-font-14 e-font-weight-400'>Units</span>
                                                    </p>
                                                </div>
                                                <div>
                                                    <ul className='ps-3  mb-2 '>
                                                        <li className={`${styles.third} w-max-content e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-600 line-height-16px`}>
                                                            {topSellingProductData.label[2]}
                                                        </li>
                                                    </ul>
                                                    <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-16 e-font-weight-600 line-height-22px'>
                                                        {topSellingProductData.data[2]} <span className='e-font-14 e-font-weight-400'>Units</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    {/* Education resources status */}
                    <div className='col-xl-6 col-lg-6 col-md-12 col-12 pt-xl-0 pt-lg-0 pt-4'>
                        <div className='d-flex flex-column h-100'>
                            <div className='e-bg-white e-leader-board-box-shadow p-4 e-border-radius-16 flex-grow-1'>
                                <h4 className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                                    Education resources status
                                </h4>
                                <div className='pt-3'>
                                    {resourceLoading ?
                                        <Loader className="h-100" /> ///loader
                                        :
                                        <>
                                            {/* Courses */}
                                            <div className={`mb-3 py-3 padding-12px-left d-flex gap-0-8 align-items-center  e-border-radius-8 cursor-pointer e-bg-azureish-white-light`}
                                                onClick={() => _handleNavigateResource(1)} >
                                                <Icon icon="course-count"
                                                    size="22px" />
                                                <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-16 e-font-weight-600 line-height-22px'>
                                                    {educationResourceData.course}
                                                    <span className='ps-1 e-font-14 e-font-weight-400'>
                                                        {educationResourceData.course === 1 ? "Course" : "Courses"}
                                                    </span>
                                                </p>
                                            </div>

                                            {/* Video snippet */}
                                            <div className={`mb-3 py-3 padding-12px-left d-flex gap-0-8 align-items-center  e-border-radius-8 cursor-pointer e-bg-lavender-blush`}
                                                onClick={() => _handleNavigateResource(2)} >
                                                <Icon icon="video-count"
                                                    size="22px" />
                                                <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-16 e-font-weight-600 line-height-22px'>
                                                    {educationResourceData.video}
                                                    <span className='ps-1 e-font-14 e-font-weight-400'>
                                                        {educationResourceData.video === 1 ? "Video snippet" : "Video snippets"}
                                                    </span>
                                                </p>
                                            </div>

                                            {/* Flash quizzes */}
                                            <div className={`py-3 padding-12px-left d-flex gap-0-8 align-items-center  e-border-radius-8 cursor-pointer e-bg-alice-blue-light`}
                                                onClick={() => _handleNavigateResource(3)} >
                                                <Icon icon="flash-quiz-count"
                                                    size="22px" />
                                                <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-16 e-font-weight-600 line-height-22px'>
                                                    {educationResourceData.flash_quiz}
                                                    <span className='ps-1 e-font-14 e-font-weight-400'>
                                                        {educationResourceData.flash_quiz === 1 ? "Flash quiz" : "Flash quizzes"}
                                                    </span>
                                                </p>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Dashboard