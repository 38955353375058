/*
 *   File : pending-request.js
 *   Author : https://evoqins.com
 *   Description : Pending request page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';

//custom component
import { DataTableComponent } from '../../../Component/DataTable';
import { CustomSearchInput } from '../../../Component/FormElements';
import { PrimaryButton } from '../../../Component/Buttons';
import { ManageRedemptionModal, RejectRequestModal } from '../../../Component/Modal';
import { Icon } from '../../../Component/Icon';
import { APIService } from '../../../Service';
import { CustomTab, Loader } from '../../../Component/Others';
import { EmptyScreen } from '../../../Component/EmptyComponent';
import { format } from 'date-fns';



// Custom data
// Tabs
const REQUEST_TABS = [
    {
        id: 1,
        label: "New joining request"
    },
    {
        id: 2,
        label: "DOB change request"
    },
];

// temp data
const TEMP_DOB_DATA = {
    data: [
        {
            name: "vijay joseph",
            email: "vijay@evoqins.com",
            phone: 9994742330,
            current_dob: "15 Aug 1947",
            new_dob: "15 Aug 2024"
        },
        {
            name: "Olivia Rhye",
            email: "oliviarhye@gmail.com",
            phone: 1134223345,
            current_dob: "27 Dec 1993",
            new_dob: "27 Dec 1994"
        },
        {
            name: "John Marcus",
            email: "oliviarhye@gmail.com",
            phone: 1134223345,
            current_dob: "27 Dec 1993",
            new_dob: "27 Dec 1994"
        },
        {
            name: "Nila Agastya",
            email: "oliviarhye@gmail.com",
            phone: 1134223345,
            current_dob: "27 Dec 1993",
            new_dob: "27 Dec 1994"
        },
        {
            name: "vijay joseph",
            email: "vijay@evoqins.com",
            phone: 9994742330,
            current_dob: "15 Aug 1947",
            new_dob: "15 Aug 2024"
        },
        {
            name: "Olivia Rhye",
            email: "oliviarhye@gmail.com",
            phone: 1134223345,
            current_dob: "27 Dec 1993",
            new_dob: "27 Dec 1994"
        },
        {
            name: "John Marcus",
            email: "oliviarhye@gmail.com",
            phone: 1134223345,
            current_dob: "27 Dec 1993",
            new_dob: "27 Dec 1994"
        },
        {
            name: "Nila Agastya",
            email: "oliviarhye@gmail.com",
            phone: 1134223345,
            current_dob: "27 Dec 1993",
            new_dob: "27 Dec 1994"
        },
    ],
    total_records: 8
}

const PendingRequest = () => {

    const navigate = useNavigate();
    const { state } = useLocation();

    const [tabIndex, setTabIndex] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [requestData, setRequestData] = useState({});
    const [customerId, setCustomerId] = useState(null);
    const [reasonList, setReasonList] = useState([]);
    const [reasonLoader, setReasonLoader] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [acceptSuccess, setAcceptSuccess] = useState(false);
    const [acceptLoading, setAcceptLoading] = useState({});

    const [dobChangeRequestData, setDobChangeRequestData] = useState({});
    const [listLoading, setListLoading] = useState(true);
    const [currentPageDob, setCurrentPageDob] = useState(1);

    const [dobChangeSuccess, setDobChangeSuccess] = useState(false);
    const [dobChangeAcceptLoading, setDobChangeAcceptLoading] = useState({});
    const [dobChangeRejectLoading, setDobChangeRejectLoading] = useState({});

    const [dobSearchValue, setDobSearchValue] = useState("");

    useEffect(() => {
        if (state !== null) {
            setTabIndex(state);
        } else {
            setTabIndex(1);
        }
    }, [state]);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

        _getRejectionReason();
    }, []);

    useEffect(() => {
        _getRegistrationList();
    }, [searchValue, currentPage]);

    useEffect(() => {
        _getDobChangeList();
    }, [dobSearchValue, currentPageDob]);

    useEffect(() => {
        if (acceptSuccess === true) {
            const accept_modal = new Modal(document.getElementById('approve-redemption-modal'));
            accept_modal.show();
        }
    }, [acceptSuccess]);

    useEffect(() => {
        if (customerId != null) {
            const reject_modal = new Modal(document.getElementById('request-reject-modal'));
            reject_modal.show();
        }
    }, [customerId]);

    useEffect(() => {
        if (dobChangeSuccess === true) {
            const accept_modal = new Modal(document.getElementById('approve-redemption-modal'));
            accept_modal.show();
        }
    }, [dobChangeSuccess]);

    // handle tab change 
    const _handleTabChange = (id) => {
        // setTabIndex(id);
        navigate("/pending-request", {
            state: id
        });
        setCurrentPage(1);
        setCurrentPageDob(1);
    };

    //handle accept request
    const _handleAccept = (id, type) => {
        setAcceptLoading(prev => ({
            ...prev,
            [id]: true
        }));

        _manageRequest(id, type);
    };

    //handle reject request
    const _handleReject = (id) => {
        setCustomerId(id);
    };

    // handle view history 
    const _handleViewHistory = () => {
        if (tabIndex === 1) {
            navigate("request-history");
        } else {
            navigate("dob-history");
        }
    };

    //handle accept dob change
    const _handleAcceptDobChange = (id, status) => {
        setDobChangeAcceptLoading(prev => ({
            ...prev,
            [id]: true
        }));

        _manageDobChange(id, status);
    };

    //handle accept dob change
    const _handleRejectDobChange = (id, status) => {
        setDobChangeRejectLoading(prev => ({
            ...prev,
            [id]: true
        }));

        _manageDobChange(id, status);
    };

    // handle page change 
    const _handlePageChange = (page_num) => {
        setCurrentPage(page_num);

        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
    };

    // handle page change dob change list 
    const _handlePageChangeDobList = (page_num) => {
        setCurrentPageDob(page_num);

        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
    };

    // registration list table column
    const NEW_REQUEST_TABLE_COLUMNS = [
        {
            name: 'Name',
            selector: row => row.name,
            width: "160px"
        },
        {
            name: 'Email',
            selector: row => row.email ? row.email : '-',
            width: "200px"
        },
        {
            name: 'Phone no.',
            selector: row => row.phone,
            width: "160px"
        },
        {
            name: 'Dob',
            selector: row => row.dob,
            width: "180px"
        },
        {
            name: 'Store name',
            selector: row => row.store_name,
            width: "250px"
        },
        {
            name: '',
            cell: (row) =>
                <div className="d-flex gap-0-8">
                    {
                        reasonLoader === false &&
                        <>
                            <PrimaryButton name="Accept & assign to me"
                                status={acceptLoading[row.id]}
                                className={`${acceptLoading[row.id] ? 'py-1' : 'padding-6px-tb'} padding-14px-lr e-accept`}
                                onPress={() => _handleAccept(row.id, true)} />
                            <PrimaryButton name="Reject"
                                className="padding-6px-tb padding-14px-lr e-reject"
                                onPress={() => _handleReject(row.id)} />
                        </>
                    }
                </div>,

            width: "260px"
        }
    ];

    // DOB change list table column
    const DOB_CHANGE_TABLE_COLUMNS = [
        {
            name: 'Name',
            selector: row => row.customer_name,
            width: "160px"
        },
        {
            name: 'Email',
            selector: row => row.email ? row.email : '-',
            width: "200px"
        },
        {
            name: 'Phone no.',
            selector: row => row.customer_mobile,
            width: "140px"
        },
        {
            name: 'Current DoB',
            cell: (row) =>
                <p className='mb-0'>
                    {format(row.data.old_data, 'PP')}
                </p>,
            width: "140px"
        },
        {
            name: 'New DoB',
            cell: (row) =>
                <p className='mb-0'>
                    {format(row.data.new_data, 'PP')}
                </p>,
            width: "140px"
        },
        {
            name: '',
            cell: (row) =>
                <div className="d-flex gap-2 align-items-center">
                    {
                        reasonLoader === false &&
                        <>
                            <PrimaryButton name="Accept"
                                status={dobChangeAcceptLoading[row.change_request_id]}
                                className={`${dobChangeAcceptLoading[row.change_request_id] ? 'py-1' : 'padding-6px-tb'} padding-14px-lr e-accept`}
                                onPress={() => _handleAcceptDobChange(row.change_request_id, 1)} />
                            <PrimaryButton name="Reject"
                                status={dobChangeRejectLoading[row.change_request_id]}
                                className={`${dobChangeRejectLoading[row.change_request_id] ? 'py-1' : 'padding-6px-tb'} h-100 padding-14px-lr e-reject`}
                                onPress={() => _handleRejectDobChange(row.change_request_id, 2)} />
                        </>
                    }
                </div>,

            width: "200px"
        }
    ];

    // API - get registration list 
    const _getRegistrationList = () => {
        let is_post = true;
        let url = 'CA/registration/list';
        let data = {
            "page_num": currentPage,
            "query": searchValue
        };
        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setRequestData(response.data);
            } else {
            }
            setLoading(false);
        });
    };

    // API - get rejection reason list 
    const _getRejectionReason = () => {
        let is_post = false;
        let url = 'CA/registration/rejection-reasons';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setReasonList(response.data);
            } else {
            }
            setReasonLoader(false);
        });
    };

    // API - accept request 
    const _manageRequest = (customer_id, type) => {
        let is_post = true;
        let url = 'CA/registration/manage';
        let data = {
            "customer_id": customer_id,
            "is_approve": type,
            "reason_id": null,
            "reason": null
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setAcceptSuccess(true);
            } else {
                setAcceptSuccess(false);
                toast.dismiss();
                toast.error(response.data.message, {
                    type: "error",
                    className: "e-toast",
                });
            }

            setAcceptLoading({});
        });
    };

    // API - get birthday change request list 
    const _getDobChangeList = () => {
        let is_post = true;
        let url = 'CA/update-request/list';
        let data = {
            "page_num": currentPageDob,
            "query": dobSearchValue,
            "filter": null
        };
        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setDobChangeRequestData(response.data);
            } else { }
            setListLoading(false);
        });
    };

    // API - Manage dob change request 
    const _manageDobChange = (request_id, status) => {
        let is_post = true;
        let url = 'CA/update-request/manage';
        let data = {
            "request_id": request_id,
            "status": status
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                if (status === 1) {
                    setDobChangeSuccess(true);
                } else {
                    toast.dismiss();
                    toast.success("Rejected successfully", {
                        type: "success",
                        className: "e-toast",
                    });
                    _getDobChangeList();
                }
            } else {
                setDobChangeSuccess(false);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }

            setDobChangeAcceptLoading({});
            setDobChangeRejectLoading({});
        });
    };

    return (
        <section className='pb-5 '>
            <div className='e-bg-lavender-blush-light pt-3 padding-98px-bottom padding-52px-lr'>
                <h1 className='mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                    Registration requests
                </h1>
            </div>
            <div className='margin-neg-66px-top padding-52px-lr'>
                <div className='e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                    <div className='row px-4'>
                        <div className='col-xl-6 col-lg-5 col-md-4 col-5'>
                            <div className="d-flex gap-3">
                                {
                                    REQUEST_TABS.map((tab) => (
                                        <div className={`${tabIndex === tab.id ? 'pe-none e-bg-lust e-text-white border-transparent' : 'e-border-1px-cool-grey e-text-rich-black cursor-pointer'}  e-border-radius-24 `}
                                            onClick={() => _handleTabChange(tab.id)} >
                                            <p className='mb-0 px-4 padding-12px-tb e-alt-font-noto-sans  e-font-14 e-font-weight-500 line-height-14px w-max-content'>
                                                {tab.label}
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-7 col-md-8 col-7 text-end'>
                            <div className='row pb-2'>
                                <div className='col-xl-4 col-lg-4 col-md-4'>
                                    <PrimaryButton name="View history"
                                        className="px-3 padding-11px-tb e-font-weight-400 e-bg-alice-blue e-text-raisin-black e-border-radius-8"
                                        onPress={_handleViewHistory} />
                                </div>
                                <div className='col-xl-8 col-lg-8 col-md-8 pt-xl-0 pt-lg-0 pt-md-0 pt-2 text-xl-end text-lg-end text-md-end text-start'>
                                    {tabIndex === 1 ?
                                        // New req list search
                                        <div className='position-relative'>
                                            <CustomSearchInput placeholder="Search by name, id & store"
                                                type="text"
                                                require
                                                value={searchValue}
                                                className="w-100 padding-10px-tb"
                                                labelStyle="e-font-weight-500"
                                                handleChange={(e) => setSearchValue(e)} />
                                            {searchValue.length !== 0 &&
                                                <Icon icon="close-icon"
                                                    className='position-absolute top-12px right-12px cursor-pointer'
                                                    size="20px"
                                                    onPress={() => setSearchValue("")} />
                                            }
                                        </div>
                                        :
                                        // Dob list search
                                        <div className='position-relative'>
                                            <CustomSearchInput placeholder="Search by name, id & store"
                                                type="text"
                                                require
                                                value={dobSearchValue}
                                                className="w-100 padding-10px-tb"
                                                labelStyle="e-font-weight-500"
                                                handleChange={(e) => setDobSearchValue(e)} />
                                            {dobSearchValue.length !== 0 &&
                                                <Icon icon="close-icon"
                                                    className='position-absolute top-12px right-12px cursor-pointer'
                                                    size="20px"
                                                    onPress={() => setDobSearchValue("")} />
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        loading ?
                            // loader 
                            <Loader />
                            :

                            <>
                                {/* New request data table */}
                                {
                                    tabIndex == 1 &&
                                    <>
                                        {
                                            requestData.total_records > 0 ?
                                                <div className='e-table'>
                                                    <DataTableComponent columns={NEW_REQUEST_TABLE_COLUMNS}
                                                        data={requestData.data}
                                                        pagination={true}
                                                        paginationServer={true}
                                                        paginationTotalRows={requestData.total_records}
                                                        onChangePage={_handlePageChange} />
                                                </div>
                                                :
                                                // empty screen
                                                <div className='h-100vh pt-5 mt-5'>
                                                    <EmptyScreen message="No requests found" />
                                                </div>
                                        }
                                    </>
                                }
                            </>
                    }

                    {listLoading === true ?
                        // loader 
                        <Loader />
                        :
                        <> {/* New request data table */}
                            {tabIndex == 2 &&
                                <>

                                    {
                                        dobChangeRequestData.total_count > 0 ?
                                            <div className='e-table'>
                                                <DataTableComponent columns={DOB_CHANGE_TABLE_COLUMNS}
                                                    data={dobChangeRequestData.data}
                                                    pagination={true}
                                                    paginationServer={true}
                                                    paginationTotalRows={dobChangeRequestData.total_count}
                                                    onChangePage={_handlePageChangeDobList} />
                                            </div>
                                            :
                                            // empty screen
                                            <div className='h-100vh pt-5 mt-5'>
                                                <EmptyScreen message="No requests found" />
                                            </div>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
            </div>

            {/* reject request modal */}
            {customerId !== null &&
                <RejectRequestModal
                    customer_id={customerId}
                    reason={reasonList}
                    handleClose={() => setCustomerId(null)}
                    success={() => _getRegistrationList()} />
            }

            {/* approve redemption modal */}
            {acceptSuccess === true &&
                <ManageRedemptionModal
                    description="The selected Sales Specialist has been added under your profile."
                    success={() => {
                        setLoading(true);
                        _getRegistrationList();
                        setAcceptSuccess(false);
                    }} />
            }

            {/* approve dob change modal */}
            {dobChangeSuccess === true &&
                <ManageRedemptionModal
                    description="The request to change the birthday has been accepted."
                    success={() => {
                        setListLoading(true);
                        _getDobChangeList();
                        setDobChangeSuccess(false);
                    }} />
            }


        </section >
    )
}

export default PendingRequest;