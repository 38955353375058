/*
 *   File : flash-quiz-detail.js
 *   Author : https://evoqins.com
 *   Description : Flash quiz detail modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//custom components
import { TextButton } from '../Buttons';
import { CustomTextInput, TextArea } from '../FormElements';
import { Loader } from '../Others';
import { Icon } from '../Icon';

// style 
import styles from "../../Style/Component/modal.module.scss";


const FlashQuizDetailModal = (props) => {

    const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [reward, setReward] = useState("");
    const [time, setTime] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {

        if (Object.keys(props.flashQuiz).length !== 0) {
            setTitle(props.flashQuiz.title);
            setDescription(props.flashQuiz.description);
            setTime(props.flashQuiz.time);
            setReward(props.flashQuiz.reward_points);
            setStartDate(props.flashQuiz.start_date);
            setEndDate(props.flashQuiz.end_date);
        }
    }, [props.flashQuiz]);

    useEffect(() => {
        if (props.response === true) {
            _handleCloseModal();
        }
    }, [props.response]);

    // handle close Modal 
    const _handleCloseModal = () => {
        var button = document.getElementById("flash-quiz-detail-modal-close");
        button.click();
        props.handleClose();
    };

    // handle edit quiz 
    const _handleEditFlashQuiz = (quiz_id) => {
        _handleCloseModal();
        navigate('/manage-resource/manage-flash-quiz', {
            state: {
                flash_quiz_id: quiz_id,
                type: "edit"
            }
        });
    };

    return (
        <>
            <div className="modal fade" id="flash-quiz-detail-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="FlashQuizDetailModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content e-border-radius-12 w-480px mx-auto ">
                        <img src={require("../../Assets/Image/CountryAdmin/close-icon.svg").default}
                            id='flash-quiz-detail-modal-close'
                            className='d-none'
                            alt='close'
                            data-bs-dismiss="modal" />

                        <div className="modal-header px-4 pt-4 pb-2 border-0 align-items-center">
                            <div>
                                <h4 className="mb-0 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px">
                                    Flash quiz details
                                </h4>
                            </div>
                            <Icon icon="close-icon"
                                className='cursor-pointer'
                                size="24px"
                                onPress={_handleCloseModal} />
                        </div>
                        {props.loading ?
                            <Loader className="h-30vh d-flex align-items-center justify-content-center py-5 my-4" /> //loader
                            :
                            <>
                                <div className={`${styles.e_modal_body_wrapper} pt-3 modal-body ps-4 pe-3 me-2 pt-0 pb-4 h-422px overflow-auto e-modal-body-wrapper`}>
                                    <div className='e-border-bottom-1px-dark-silver'>
                                        {/* Title  */}
                                        <CustomTextInput label="Quiz title"
                                            placeholder="Successful negotiation essential strategies & skills"
                                            type="text"
                                            value={title}
                                            readOnly={true}
                                            className="w-100 padding-10px-tb"
                                            labelStyle="e-font-weight-500 e-text-raisin-black"
                                            borderStyle="e-border-1px-platinum-light"
                                            handleChange={(e) => setTitle(e)} />

                                        {/* description */}
                                        <TextArea label="Quiz description"
                                            placeholder="Enter your text"
                                            type="text"
                                            rows="3"
                                            value={description}
                                            readOnly={true}
                                            className="w-100 px-3 padding-10px-tb"
                                            handleChange={(e) => setDescription(e)} />

                                        <div className='row'>
                                            {/* Quiz reward */}
                                            <div className='col-6 pe-2'>
                                                <CustomTextInput label="Quiz rewards"
                                                    placeholder="reward"
                                                    type="text"
                                                    value={reward}
                                                    readOnly={true}
                                                    className="w-100 padding-10px-tb"
                                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                                    borderStyle="e-border-1px-platinum-light"
                                                    handleChange={(e) => setReward(e)} />
                                            </div>
                                            {/* Quiz time */}
                                            <div className='col-6 ps-2'>
                                                <CustomTextInput label="Quiz time"
                                                    placeholder="2 min"
                                                    type="text"
                                                    value={time}
                                                    readOnly={true}
                                                    className="w-100 padding-10px-tb"
                                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                                    borderStyle="e-border-1px-platinum-light"
                                                    handleChange={(e) => setTime(e)} />
                                            </div>
                                        </div>

                                        <div className='row mb-4'>
                                            {/* Quiz start date */}
                                            <div className='col-6 pe-2'>
                                                <CustomTextInput label="Quiz start date"
                                                    placeholder="04 April 2024"
                                                    type="text"
                                                    value={startDate}
                                                    readOnly={true}
                                                    className="w-100 padding-10px-tb"
                                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                                    borderStyle="e-border-1px-platinum-light"
                                                    handleChange={(e) => setStartDate(e)} />
                                            </div>
                                            {/* Quiz end date */}
                                            <div className='col-6 ps-2'>
                                                <CustomTextInput label="Quiz end date"
                                                    placeholder="05 April 2024"
                                                    type="text"
                                                    value={endDate}
                                                    readOnly={true}
                                                    className="w-100 padding-10px-tb"
                                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                                    borderStyle="e-border-1px-platinum-light"
                                                    handleChange={(e) => setEndDate(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* questions */}
                                    {props.flashQuiz.question.map((quiz, key) => (
                                        <div key={key}>
                                            <div className='pt-3 pb-2'>
                                                {/* Question */}
                                                <CustomTextInput label="Question"
                                                    placeholder="hint"
                                                    type="text"
                                                    value={quiz.question}
                                                    readOnly={true}
                                                    className="w-100 padding-10px-tb"
                                                    labelStyle="e-font-weight-600 e-text-raisin-black"
                                                    borderStyle="e-border-1px-platinum-light" />
                                            </div>

                                            {/* Answers */}
                                            {quiz.answers.map((answer, key) => (
                                                <div key={key} className='position-relative pb-2'>
                                                    <CustomTextInput label={key === 0 ? 'Answers' : ''}
                                                        placeholder="Answer"
                                                        type="text"
                                                        value={answer.answer}
                                                        readOnly={true}
                                                        className="w-100 padding-10px-tb"
                                                        labelStyle="e-font-weight-500 e-text-raisin-black"
                                                        borderStyle="e-border-1px-platinum-light" />
                                                    <div className={`${key === 0 ? 'top-34px' : 'top-8px'} position-absolute right-16px`}>
                                                        {answer.is_answer === true &&
                                                            <img src={require("../../Assets/Image/CountryAdmin/ManageResource/correct-answer.png")}
                                                                className=''
                                                                draggable={false}
                                                                width={16}
                                                                height={16}
                                                                alt='correct' />
                                                        }
                                                    </div>
                                                </div>
                                            ))}

                                            <div className='pt-2 pb-1'>
                                                {/* hint */}
                                                <CustomTextInput label="Hint"
                                                    placeholder="hint"
                                                    type="text"
                                                    value={quiz.hint}
                                                    readOnly={true}
                                                    className="w-100 padding-10px-tb"
                                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                                    borderStyle="e-border-1px-platinum-light" />
                                            </div>
                                        </div>
                                    ))}

                                </div>
                                <div className='text-end px-4 pb-4'>
                                    <div className='e-inline-flex justify-content-end gap-0-24px'>
                                        <TextButton name="Edit"
                                            className="e-font-16"
                                            onPress={() => _handleEditFlashQuiz(props.flashQuiz.flash_quiz_id)} />
                                        <TextButton name="Delete"
                                            className="e-text-lust e-font-16"
                                            onPress={() => props.handleDelete(props.flashQuiz.flash_quiz_id)} />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default memo(FlashQuizDetailModal);