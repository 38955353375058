/*
 *   File : quiz.js
 *   Author : https://evoqins.com
 *   Description : Quiz card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo } from 'react';
import { Modal } from 'bootstrap';

//Custom components
import { TextButton } from '../Buttons';

//style
import styles from "../../Style/Component/card.module.scss";

const QuizCard = (props) => {

    // handle view quiz detail
    const _handleViewDetail = () => {
        const detail_modal = new Modal(document.getElementById('quiz-detail-modal'));
        detail_modal.show();
    }

    return (
        <div className={`${styles.e_quiz_card_wrapper} e-admin-box-shadow e-border-radius-16 p-3 `}>
            <div className='e-display-flex justify-content-space-between'>
                <div className='d-flex gap-0-16 align-items-baseline'>
                    <p className='mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-16 e-font-weight-700 line-height-14px'>
                        {props.data.points}
                        <span className='ps-1 e-font-14 e-font-weight-400 line-height-12px'>
                            points
                        </span>
                    </p>
                    <p className='mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-14 e-font-weight-400 line-height-20px'>
                        {props.data.duration_text}
                    </p>
                </div>
            </div>
            <div className='padding-12px-top'>
                <h6 className='mb-0 e-primary-font-playfair e-text-raisin-black e-font-16 e-font-weight-700 line-height-18px e-ellipse'>
                    <span className='e-font-weight-400'>{props.data.title}</span>
                </h6>
                <p className='mb-0 padding-12px-top e-alt-font-noto-sans e-text-davys-grey e-font-14 e-font-weight-400 line-height-20px e-ellipse-4-line'>
                    {props.data.description}
                </p>

                <div className='pt-4 pb-1 px-2 text-end'>
                    <TextButton name="View details"
                        onPress={_handleViewDetail} />
                </div>
            </div>
        </div>
    )
}

export default memo(QuizCard);