/*
 *   File : statistics.js
 *   Author : https://evoqins.com
 *   Description : Statistics card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo } from 'react';

//custom style
import styles from "../../Style/Component/card.module.scss";

const StatisticCard = (props) => {
    return (
        <div className={`${styles.e_statistics_card_wrapper} ${props.className} transition e-bg-white e-border-radius-8 p-4 `}>

            <img src={props.icon}
                draggable={false}
                alt='icon' />
            {props.loader ?
                <div class="pt-3 placeholder-glow ">
                    <p class="mb-0 h-34px placeholder col-5 e-border-radius-8 e-bg-columbia-blue"></p>
                </div>
                :
                <p className={`pt-3 mb-0 e-text-black e-alt-font-noto-sans e-font-weight-400 e-font-32 line-height-34px`}>
                    {props.quantity}
                </p>
            }
            <p className={`pt-3 mb-0  e-text-sonic-silver e-alt-font-noto-sans e-font-weight-500 e-font-16 line-height-16px w-max-content`}>
                {props.title}
            </p>
        </div >

    )
}

export default memo(StatisticCard);