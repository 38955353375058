/*
 *   File : add-offer.js
 *   Author : https://evoqins.com
 *   Description : Add new offer modal
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// Custom components
import { CustomFileUpload, CustomTextInput } from '../FormElements';
import { PrimaryButton, SecondaryButton, TextButton } from '../Buttons';
import { APIService, AWSService } from '../../Service';
import { Icon } from '../Icon';

// style 
import styles from "../../Style/Component/modal.module.scss";

const AddOfferModal = (props) => {

    const [offerTitle, setOfferTitle] = useState("");
    const [offerTitleError, setOfferTitleError] = useState("");
    const [redeemPoint, setRedeemPoint] = useState("");
    const [redeemPointError, setRedeemPointError] = useState("");
    const [image, setImage] = useState(null);
    const [imageError, setImageError] = useState("");
    const [success, setSuccess] = useState(false);
    const [editData, setEditData] = useState(props.offerData);
    const [imageLoader, setImageLoader] = useState(false);
    const [createLoader, setCreateLoader] = useState(true);
    const [errorUpdate, setErrorUpdate] = useState(false);

    useEffect(() => {
        if (Object.keys(props.offerData).length !== 0) {
            setEditData(props.offerData);
        }
        setCreateLoader(false);
        setImageLoader(false);
    }, [props.offerData]);

    useEffect(() => {
        if (editData.type === "edit") {
            setOfferTitle(editData.data.title);
            setImage(editData.data.banner_url);
            setRedeemPoint(editData.data.reward_points);
        }
    }, [editData]);

    useEffect(() => {
        setImageError("");
    }, [image]);

    useEffect(() => {
        setOfferTitleError("");
    }, [offerTitle]);

    useEffect(() => {
        setRedeemPointError("");
    }, [redeemPoint]);

    // handle upload scheduled image
    const _uploadedImage = (url) => {
        setImageLoader(true);
        _getPresignedUrl(url);
    };

    // handle close Modal 
    const _handleCloseModal = () => {
        var button = document.getElementById("add-offer-modal-close");
        button.click();

        setImageError("");
        setOfferTitleError("");
        setRedeemPointError("");
        setImage(null);
        setOfferTitle("");
        setRedeemPoint("");
        setSuccess(false);

        props.handleClose();
    };

    // handle close Modal 
    const _handleSuccess = () => {
        var button = document.getElementById("add-offer-modal-close");
        button.click();

        setImageError("");
        setOfferTitleError("");
        setRedeemPointError("");
        setImage(null);
        setOfferTitle("");
        setRedeemPoint("");
        setSuccess(false);
        props.handleClose();
        props.success();
    }

    // handle submit offer
    const _handleSubmitOffer = () => {

        let is_valid = true;

        if (offerTitle.trim().length === 0) {
            setOfferTitleError("Title id required");
            is_valid = false;
        }

        if (redeemPoint.length === 0) {
            setRedeemPointError("Redeem point is required");
            is_valid = false;
        }
        if (image === null) {
            setImageError("Image is required");
            is_valid = false;
        }

        setErrorUpdate(prev => !prev);

        if (is_valid === true) {
            setCreateLoader(true);
            if (editData.type === "edit") {
                _updateCoupon();
            } else {
                _createCoupon();
            }
        }
    };


    // get presigned url
    async function _getPresignedUrl(file) {
        let is_post = true;
        let url = 'general/file-upload';
        let data = {
            "file": file.name,
            "is_public": true
        };
        try {
            let response = await APIService(is_post, url, data);
            if (response.status_code == 200) {
                let aws_fields = response.data.upload_info.fields;
                let aws_url = response.data.upload_info.url;

                try {
                    let aws_response = await AWSService(aws_fields, file, aws_url);
                    if (aws_response.status == 'ok') {
                        setImage(response.data.view_info);
                    } else {
                        toast.dismiss();
                        toast.error(aws_response.message, {
                            type: "error",
                            className: "e-toast",
                        });
                    }
                } catch (err) {
                    console.log("error")
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        } catch (err) {
        }
        setImageLoader(false);
    };

    // API - Create coupon 
    const _createCoupon = () => {
        let is_post = true;
        let url = 'CA/coupon/create';
        let data = {
            "title": offerTitle,
            "banner_url": image,
            "reward_points": redeemPoint
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess(true);
                setImage(null);

                setOfferTitle("");
                setRedeemPoint("");
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setCreateLoader(false);
        });
    };

    // API - Update coupon 
    const _updateCoupon = () => {
        let is_post = true;
        let url = 'CA/coupon/edit';
        let data = {
            "coupon_id": editData.data.coupon_id,
            "title": offerTitle,
            "banner_url": image,
            "reward_points": redeemPoint
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess(true);
                setImage(null);

                setOfferTitle("");
                setRedeemPoint("");
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setCreateLoader(false);
        });
    };

    return (
        <div className="modal fade" id="add-offer-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="addOfferModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content e-border-radius-12 w-480px mx-auto ">
                    <img src={require("../../Assets/Image/CountryAdmin/close-icon.svg").default}
                        id='add-offer-modal-close'
                        className='d-none'
                        alt='close'
                        data-bs-dismiss="modal" />
                    {!success ?
                        <>
                            <div className="modal-header px-4 pt-4 pb-2 border-0">
                                <h4 className="mb-0 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px">
                                    {editData.type === "edit" ? 'Edit offer' : 'Add new offers'}
                                </h4>
                                <Icon icon="close-icon"
                                    className='cursor-pointer'
                                    size="24px"
                                    onPress={_handleCloseModal} />

                            </div>
                            <div className={`${styles.e_modal_body_wrapper} modal-body px-4 pt-0 pb-4 h-470px overflow-auto e-modal-body-wrapper`}>
                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                    Add a new offer to the list of redeem offer
                                </p>
                                <div className="pt-3">
                                    {/* offer title */}
                                    <CustomTextInput label="Offer title"
                                        placeholder="Holiday trip"
                                        type="text"
                                        require
                                        value={offerTitle}
                                        error={offerTitleError}
                                        className="w-100"
                                        labelStyle="e-font-weight-500 e-text-raisin-black"
                                        handleChange={(e) => setOfferTitle(e)} />

                                    {/* redeemable pont */}
                                    <CustomTextInput label="Offer redeemable point"
                                        placeholder="10,000"
                                        type="number"
                                        require
                                        value={redeemPoint}
                                        error={redeemPointError}
                                        className="w-100"
                                        labelStyle="e-font-weight-500 e-text-raisin-black"
                                        handleChange={(e) => setRedeemPoint(e)} />

                                    {/* upload thumbnail */}
                                    <CustomFileUpload label="Upload image"
                                        placeholder="Drop your image here"
                                        file={image}
                                        fileType={["JPG", "PNG", "JPEG"]}
                                        resolution={"328px * 176px"}
                                        requiredWidth={328}
                                        requiredHeight={176}
                                        errorUpdate={errorUpdate}
                                        require
                                        loader={imageLoader}
                                        error={imageError}
                                        handleChange={_uploadedImage} />

                                    {/* change thumbnail button */}
                                    {image !== null &&
                                        <div className='mb-2 margin-neg-20px-top'>
                                            <TextButton name="Change thumbnail"
                                                onPress={() => setImage(null)} />
                                        </div>
                                    }

                                    {/* Custom button  */}
                                    <div className='row row-cols-2 pt-3'>
                                        <div className='col'>
                                            <SecondaryButton name="Cancel"
                                                className="w-100"
                                                onPress={_handleCloseModal} />
                                        </div>
                                        <div className='col'>
                                            <PrimaryButton name={editData.type === "edit" ? 'Update' : `Submit`}
                                                className="w-100"
                                                status={createLoader}
                                                onPress={_handleSubmitOffer} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="modal-header px-4 pt-4 pb-2 border-0 align-items-start">
                                <img src={require("../../Assets/Image/CountryAdmin/success-icon.svg").default}
                                    draggable={false}
                                    alt='icon' />
                                <Icon icon="close-icon"
                                    className='cursor-pointer'
                                    size="24px"
                                    onPress={_handleSuccess} />
                                <button type="button" className="btn-close shadow-none d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body px-4 pb-4 pt-2">
                                <h6 className='mb-2 e-primary-font-playfair e-font-18 e-text-raisin-black e-font-weight-500 line-height-28px'>
                                    Offer {editData.type === "edit" ? "updated" : "added"} successfully
                                </h6>
                                <p className='mb-0 e-text-davys-gray e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                    A new offer has been {editData.type === "edit" ? "updated" : "added"} successfully. It will be listed in the redeem offer section.
                                </p>
                                <div className='pt-4 mt-2'>
                                    <PrimaryButton name="Ok"
                                        className="w-100"
                                        onPress={_handleSuccess} />
                                </div>
                            </div>
                        </>
                    }

                </div>
            </div>
        </div>
    )
}

export default memo(AddOfferModal);