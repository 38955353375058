/*
 *   File : listing.js
 *   Author : https://evoqins.com
 *   Description : Store listing page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Custom component 
import { DataTableComponent } from '../../../Component/DataTable';
import { CustomSearchInput } from '../../../Component/FormElements';
import { Icon } from '../../../Component/Icon';
import { APIService } from '../../../Service';
import { EmptyScreen } from '../../../Component/EmptyComponent';
import { Loader } from '../../../Component/Others';

const ITEMS_PER_PAGE = 15;

const StoreListing = () => {

    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState("");
    const [storeData, setStoreData] = useState({});
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, []);

    useEffect(() => {
        _getStoreList();
    }, [currentPage, searchValue]);

    // handle search store 
    const _handleSearchStore = (value) => {
        setSearchValue(value);
        setLoading(true);
        setCurrentPage(1);
    };

    // handle paginate store list 
    const _handlePageChange = (page_num) => {
        setCurrentPage(page_num);

        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
    };

    // handle navigate store detail 
    const _handleNavigateDetail = (store_id) => {
        navigate('detail', {
            state: {
                store_id: store_id,
                tabIndex: 1
            }
        });
    };

    // store list table 
    const TABLE_COLUMNS = [
        {
            name: 'Store name',
            selector: row => row.name,
            width: "260px"
        },
        {
            name: 'Location',
            selector: row => row.location ? row.location : '-',
        },
        {
            name: 'Store ID',
            selector: row => row.shop_code === null ? '-' : row.shop_code,
            width: "140px"
        },
        {
            name: 'Phone no.',
            selector: row => row.phone === null ? '-' : row.phone,
            width: "160px"
        },
        {
            name: 'Sales specialists',
            selector: row => row.sales_specialist,
            width: "160px"
        },
        {
            name: 'Sales (Units)',
            selector: row => row.sales_unit,
        },
        {
            name: 'Revenue generated',
            selector: row => row.revenue,
            width: "180px"
        }
    ];

    // API - Get all stores
    const _getStoreList = () => {
        let is_post = true;
        let url = 'CA/store/list';
        let data = {
            "page_num": currentPage,
            "query": searchValue,
            "limit": ITEMS_PER_PAGE
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setStoreData(response.data);
            } else { }
            setLoading(false);
        });
    };

    return (
        <section className='pb-5 '>
            <div className='e-bg-lavender-blush-light pt-3 padding-98px-bottom padding-52px-lr'>
                <h1 className='mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                    Stores
                </h1>
            </div>

            {/* Stores */}
            <div className='margin-neg-66px-top padding-52px-lr'>
                <div className='e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                    <div className='row px-4'>
                        <div className='col-xl-8 col-lg-8 col-md-5 col-sm-4'>
                            <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-600 line-height-28px' >
                                All stores
                            </h4>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-7 col-sm-8 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-2 text-end'>
                            <div className='position-relative'>
                                <CustomSearchInput placeholder="Search by store name & id"
                                    type="text"
                                    require
                                    value={searchValue}
                                    className="w-100 padding-10px-tb"
                                    labelStyle="e-font-weight-500"
                                    handleChange={(e) => _handleSearchStore(e)} />
                                {searchValue.length !== 0 &&
                                    <Icon icon="close-icon"
                                        className='position-absolute top-12px right-12px cursor-pointer'
                                        size="20px"
                                        onPress={() => {
                                            setSearchValue("");
                                            setLoading(true);
                                        }} />
                                }
                            </div>

                        </div>
                    </div>

                    {loading ?
                        // loader 
                        <Loader />
                        :
                        <>
                            {/* Store detail data table */}
                            {storeData.data.length !== 0 ?
                                <div className='e-table e-store-table'>
                                    <DataTableComponent columns={TABLE_COLUMNS}
                                        pagination={true}
                                        data={storeData.data}
                                        paginationServer={true}
                                        paginationTotalRows={storeData.total_count}
                                        onChangePage={_handlePageChange}
                                        rowClick={(row) => _handleNavigateDetail(row.store_id)} />
                                </div>
                                :
                                // empty screen
                                <div className='h-100vh pt-5 mt-5'>
                                    <EmptyScreen message="No stores found" />
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </section>
    )
}

export default StoreListing;