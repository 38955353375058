
/*
 *   File : resend-push-notification.js
 *   Author : https://evoqins.com
 *   Description : Resend push notification
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react'

// Components
import { Icon } from '../Icon';
import { CustomTextInput, TextArea } from '../FormElements';
import { PrimaryButton } from '../Buttons';
import { APIService } from '../../Service';
import { toast } from 'react-toastify';

function ResendPushNotification(props) {

    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState("");
    const [content, setContent] = useState("");
    const [contentError, setContentError] = useState("");
    const [sendCreateLoader, setSendCreateLoader] = useState(false);

    useEffect(() => {
        setTitle(props.data.title);
        setContent(props.data.description);
    }, [props.data]);

    useEffect(() => {
        setTitleError("");
    }, [title]);

    useEffect(() => {
        setContentError("");
    }, [content]);

    // handle send push notification
    const _handleSendPushNotification = () => {

        let is_valid = true;
        if (title.trim().length === 0) {
            setTitleError("Notification title is required");
            is_valid = false;
        }
        if (content.trim().length === 0) {
            setContentError("Notification message is required");
            is_valid = false;
        }

        if (is_valid === true) {
            setSendCreateLoader(true);
            _sendPushNotification();
        }
    };

    // handle close modal
    const _handleCloseModal = () => {
        var button = document.getElementById("resend-notification-modal-close");
        button.click();
        props.onCloseModal();
    }

    // API - Send push notification
    const _sendPushNotification = () => {
        let is_post = true;
        let url = 'CA/notifications/resend-notification';
        let data = {
            "notification_id": props.data.notification_id,
            "title": title,
            "description": content,
            "image_url": props.data.image_url
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                toast.dismiss();
                toast.success("Notification resend successfully", {
                    className: "e-toast",
                });
                _handleCloseModal();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                })
            }
            setSendCreateLoader(false);
        });
    };

    return (
        <div className="modal fade"
            id="resend-push-notification-modal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="confirmBannerAction"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content e-border-radius-12">
                    <div className="modal-header px-4 pt-4 pb-1 border-0 align-items-start">
                        <button id='resend-notification-modal-close' type="button" className="btn-close d-none shadow-none" data-bs-dismiss="modal" aria-label="Close" ></button>

                        <h3 className='e-text-dark-jungle-green e-primary-font-playfair e-font-18 e-font-weight-500 line-height-28px'>
                            Preview notification
                        </h3>
                        <Icon icon="close-icon"
                            className='cursor-pointer'
                            size="24px"
                            data-bs-dismiss="modal"
                            onClick={_handleCloseModal} />
                    </div>
                    <p className='px-4 mb-3 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                        Re-send push notification
                    </p>
                    <div className="modal-body px-4 py-0 e-modal-body-scrollbar">
                        <CustomTextInput label="Title"
                            placeholder="Notification title"
                            type="text"
                            value={title}
                            require
                            readOnly={true}
                            error={titleError}
                            className="w-100"
                            labelStyle="e-font-weight-500 e-text-raisin-black"
                            handleChange={(e) => setTitle(e)} />
                        <div className='pt-2'>
                            <TextArea label="Content"
                                placeholder="Enter your text..."
                                type="text"
                                require
                                rows="3"
                                readOnly={true}
                                value={content}
                                error={contentError}
                                className=" px-3 padding-12px-tb w-100"
                                handleChange={(e) => setContent(e)} />
                        </div>
                        <div className='mt-1'>
                            <img src={props.data.image_url}
                                draggable={false}
                                className='w-100 e-border-radius-16  object-fit-cover'
                                height={120}
                                alt='notification' />
                        </div>
                    </div>
                    <div className="modal-footer pb-4 pt-3 px-4 border-0">
                        <PrimaryButton name="Send"
                            className="w-100 mt-2"
                            status={sendCreateLoader}
                            onPress={_handleSendPushNotification} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResendPushNotification;