/*
 *   File : text-input.js
 *   Author : https://evoqins.com
 *   Description : Text input component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo, useState } from 'react';

//custom style
import styles from "../../Style/Component/input.module.scss";

const CustomTextInput = (props) => {

    const [isInputFocused, setIsInputFocused] = useState(false);

    // handle focus 
    const _handleFocus = () => {
        setIsInputFocused(true);
    };

    const _handleBlur = () => {
        setIsInputFocused(false);
    };

    //input handle change
    const _handleChange = (e) => {
        let value = e.target.value;
        props.handleChange(value);
    };

    // handle enter key press
    const keyPress = (e) => {
        if (e.keyCode === 13) {
            props.onKeyPress();
        }
    };

    const handleKeyDown = (e) => {
        // Prevent default behavior of up and down arrow keys
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
        if (props.type === "number") {
            if (e.key === '0' && props.value === '') {
                e.preventDefault();
            }
        }
        if (e.key === '.' && props.not_decimal) {
            e.preventDefault();
        }
    };


    return (
        <div ref={props.ref} >
            {props.label &&
                <label className={`${props.labelStyle} e-text-rich-black e-alt-font-noto-sans e-font-weight-500 line-height-18px e-font-14 padding-6px-bottom`}>
                    {props.label}
                    {props.require &&
                        <span className='e-text-lust'>*</span>
                    }
                </label>
            }

            <div className={`${!props.search && !props.readOnly && `h-64px`}`}>
                <div className={` ${props.error && 'border-red-1px '} ${isInputFocused ? `e-border-1px-indigo ` : `e-border-1px-columbia-blue ${props.borderStyle}`} e-border-radius-8`}>
                    <input id={props.id}
                        ref={props.ref}
                        value={props.value}
                        defaultValue={props.defaultValue}
                        maxLength={props.maxLength}
                        tabIndex={props.tabIndex}
                        type={props.type}
                        required={props.required}
                        readOnly={props.readOnly}
                        autoFocus={props.autoFocus}
                        placeholder={props.placeholder}
                        className={`${styles.e_input} ${props.className} px-3 padding-12px-tb border-0 e-border-radius-8 e-bg-white e-font-14 e-text-raisin-black e-font-weight-400 line-height-22px e-alt-font-noto-sans`}
                        onWheel={() => document.activeElement.blur()}
                        onFocus={_handleFocus}
                        onBlur={_handleBlur}
                        onChange={_handleChange}
                        // onKeyDown={(e) => keyPress(e)}
                        onKeyDown={(e) => {
                            keyPress(e);
                            handleKeyDown(e);
                        }}
                        onClick={props.onClick} />

                </div>
                {props.error &&
                    <p className='mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px pt-1'>
                        {props.error}
                    </p>
                }
            </div>
        </div >
    )
}

CustomTextInput.defaultProps = {
    onKeyPress: () => { },
    className: null,
    error: "",
    value: "",
}

export default memo(CustomTextInput);