/*   File : manage-kpi.js
 *   Author : https://evoqins.com
 *   Description : Manage kpi modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Modal } from 'bootstrap';

// style 
import styles from "../../Style/Component/modal.module.scss";

//Custom components
import { Icon } from '../Icon';
import { CustomDatePicker, CustomSelectBox, CustomTextInput } from '../FormElements';
import { OverLay } from '../Others';
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { toast } from 'react-toastify';
import { APIService } from '../../Service';

const ManageKpiModal = (props) => {

    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState("");
    const [rewards, setRewards] = useState("");
    const [rewardsError, setRewardsError] = useState("");
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [startDateError, setStartDateError] = useState("");
    const [startDateToggle, setStartDateToggle] = useState(false);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [endDateError, setEndDateError] = useState("");
    const [endDateToggle, setEndDateToggle] = useState(false);
    const [salesTarget, setSalesTarget] = useState("");
    const [salesTargetError, setSalesTargetError] = useState("");
    const [targetProduct, setTargetProduct] = useState(null);
    const [targetProductError, setTargetProductError] = useState("");
    const [createLoader, setCreateLoader] = useState(true);
    const [kpiId, setKpiId] = useState(null);

    useEffect(() => {
        setCreateLoader(false);
    }, []);

    useEffect(() => {
        if (props.editData.type === "edit") {
            setTitle(props.editData.data.title);
            setRewards(props.editData.data.points);
            setSalesTarget(props.editData.data.target);
            setTargetProduct({ value: props.editData.data.product_id, label: props.editData.data.product_name });
            setSelectedStartDate(props.editData.data.start_date);
            setSelectedEndDate(props.editData.data.end_date);
            setKpiId(props.editData.data.id);
        }

    }, [props.editData]);

    useEffect(() => {
        if (startDateToggle === true) {
            const start_date = new Modal(document.getElementById('start-date-modal'));
            start_date.show();
        }
    }, [startDateToggle]);

    useEffect(() => {
        if (endDateToggle === true) {
            const end_date = new Modal(document.getElementById('end-date-modal'));
            end_date.show();
        }
    }, [endDateToggle]);

    // handle close modal
    const _handleCloseModal = () => {
        var button = document.getElementById("manage-kpi-modal-close");
        button.click();

        props.handleClose();
    };

    // start date toggle show an hide
    const _handleStartDateToggle = () => {
        setStartDateToggle(!startDateToggle);
    };

    // handle select start date
    const _handleSelectedStartDate = (day) => {
        setSelectedStartDate(day);

        setStartDateToggle(false);
        _handleCloseStartDateModal();
    };

    // handle close start date modal 
    const _handleCloseStartDateModal = () => {
        var button = document.getElementById("start-date-modal-close");
        button.click();
        setStartDateToggle(false);
    }

    // end date toggle show an hide
    const _handleEndDateToggle = () => {
        setEndDateToggle(!endDateToggle);
    };

    // handle select end date
    const _handleSelectedEndDate = (day) => {
        setSelectedEndDate(day);

        setEndDateToggle(false);
        _handleCloseEndDateModal();
    };

    // handle close end date modal 
    const _handleCloseEndDateModal = () => {
        var button = document.getElementById("end-date-modal-close");
        button.click();
        setEndDateToggle(false);
    };


    // handleScroll
    const _handleScroll = (element_id) => {
        if (document.getElementById(element_id)) {
            setTimeout(() => {
                document.getElementById(element_id).scrollIntoView({ behavior: 'smooth', block: "nearest", inline: "nearest" });
            }, 1);
        }
    };

    // handle focus error message
    const _focusError = (error_element_id) => {
        if (document.getElementById(error_element_id)) {
            document.getElementById(error_element_id).scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    // handle create and edit kpi 
    const _handleManageKpi = () => {

        let is_valid = true;
        let error_field = [];

        if (title.trim().length === 0) {
            setTitleError("Title is required");
            error_field.push('widget-title');
            is_valid = false;
        }

        if (rewards.length == 0) {
            setRewardsError("Reward is required");
            error_field.push('reward-point');
            is_valid = false;
        }

        if (targetProduct === null) {
            setTargetProductError("Select target product");
            error_field.push('target-product');
            is_valid = false;
        }

        if (salesTarget.length === 0) {
            setSalesTargetError("Sales target is required");
            error_field.push('target-sales');
            is_valid = false;
        }

        if (selectedStartDate === null) {
            setStartDateError("Start date is required");
            error_field.push('start-date');
            is_valid = false;
        }

        if (selectedEndDate === null) {
            setEndDateError("End date is required");
            error_field.push('end-date');
            is_valid = false;
        }

        if (is_valid === true) {
            _manageKpi();  //API- to manage kpi
        } else {
            if (error_field.length > 0) {
                _focusError(error_field[0]);
            }
        }

    };

    // API - Create kpi
    const _manageKpi = () => {
        setCreateLoader(true);
        let is_post = true;
        let url = 'CA/custom-kpi/upsert';
        let data = {
            "id": kpiId,
            "product_id": targetProduct.value,
            "start_date": format(selectedStartDate, 'yyyy-MM-dd'),
            "end_date": format(selectedEndDate, 'yyyy-MM-dd'),
            "title": title,
            "points": rewards,
            "target": salesTarget
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                toast.dismiss();
                toast.success(kpiId === null ? "Widget created successfully" :
                    "Widget updated successfully", {
                    type: "success",
                    className: "e-toast",
                });

                _handleCloseModal();
                props.success();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setCreateLoader(false);
        });
    };

    return (
        <div className="modal fade"
            id="manage-kpi-modal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="manageKpiModalLabel"
            aria-hidden="true">

            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable e-border-radius-24">
                <div className="modal-content e-border-radius-12 overflow-hidden">
                    <img src={require("../../Assets/Image/CountryAdmin/close-icon.svg").default}
                        draggable={false}
                        id='manage-kpi-modal-close'
                        className='d-none '
                        alt='close'
                        data-bs-dismiss="modal" />

                    {/* Header */}
                    <div className="modal-header align-items-start flex-column px-4 py-4 border-0">
                        <div className='d-flex justify-content-between w-100 mb-2'>
                            <h4 className="mb-0 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px">
                                {kpiId === null ? "Create widget" : "Edit widget"}
                            </h4>
                            <Icon icon="close-icon"
                                className='cursor-pointer'
                                size="24px"
                                onPress={_handleCloseModal} />
                        </div>
                        <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                            Create a new widget for the target product.
                        </p>
                    </div>

                    <div className={`${styles.e_modal_body_wrapper} pt-0 modal-body ps-4 pe-3 me-2 pb-2 overflow-auto e-modal-body-wrapper`}>
                        <div >
                            {/* Title*/}
                            <div id="widget-title">
                                <CustomTextInput label="Widget title"
                                    placeholder="Widget title"
                                    type="text"
                                    require
                                    value={title}
                                    error={titleError}
                                    className="w-100 padding-10px-tb"
                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                    handleChange={(e) => setTitle(e)} />
                            </div>

                            {/* Rewards points */}
                            <div id="reward-point">
                                <CustomTextInput label="Rewards point"
                                    placeholder="Rewards point"
                                    type="number"
                                    require
                                    value={rewards}
                                    error={rewardsError}
                                    className="w-100 padding-10px-tb"
                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                    handleChange={(e) => setRewards(e)} />
                            </div>

                            {/* Target product */}
                            <div className='row'>
                                {/* Sales target units */}
                                <div className='col-6' id="target-sales">
                                    <CustomTextInput label="Sales target (units)"
                                        placeholder="50"
                                        type="number"
                                        require
                                        value={salesTarget}
                                        error={salesTargetError}
                                        className="w-100 padding-10px-tb"
                                        labelStyle="e-font-weight-500 e-text-raisin-black"
                                        handleChange={(e) => setSalesTarget(e)} />
                                </div>
                                {/* Target product selector */}
                                <div className='col-6'
                                    id="target-product">
                                    <CustomSelectBox label="Target product"
                                        value={targetProduct}
                                        require
                                        options={props.product}
                                        error={targetProductError}
                                        className="e-modal-select"
                                        labelClass="padding-5px-bottom"
                                        menuPortalTarget={document.body}
                                        isSearchable={true}
                                        onMenuOpen={() => _handleScroll("target-product")}
                                        selectChange={(id) => { setTargetProduct(id); }} />
                                </div>
                            </div>

                            {/* date select */}
                            <div className='row'>
                                {/* start date picker */}
                                <div className='col-6 pe-2' id="start-date">
                                    <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                        Start date<span className='e-text-lust'>*</span>
                                    </p>

                                    <div className='pt-2 h-70px position-relative'>
                                        <div className={`${startDateToggle ? 'e-border-1px-indigo' : 'e-border-1px-platinum-light'} e-display-flex gap-0-4px e-border-radius-6 padding-14px-tb px-2 cursor-pointer`}
                                            onClick={_handleStartDateToggle}>
                                            <img src={require("../../Assets/Image/CountryAdmin/Notification/calendar-icon.svg").default}
                                                draggable={false}
                                                alt='calender-icon' />
                                            {selectedStartDate !== null ?
                                                <>
                                                    <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                                        {format(selectedStartDate, 'PP')}
                                                    </p>

                                                </>
                                                :
                                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                                    Choose start date
                                                </p>
                                            }

                                        </div>
                                        <p className='mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px padding-2-top'>
                                            {startDateError}
                                        </p>

                                        {selectedStartDate !== null &&
                                            <Icon icon="close-icon"
                                                className='position-absolute top-20px right-8px cursor-pointer'
                                                size="20px"
                                                onPress={() => {
                                                    setSelectedStartDate(null);
                                                    setSelectedEndDate(null);
                                                }} />
                                        }
                                    </div>

                                    {/* date picker */}
                                    {startDateToggle &&
                                        <div className="modal fade" id="start-date-modal" data-bs-backdrop="false" tabIndex="-1" aria-labelledby="starDateModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered e-border-radius-24">
                                                <button id='start-date-modal-close'
                                                    className='d-none '
                                                    data-bs-dismiss="modal" />
                                                <div className="modal-content w-358px  mx-auto border-0 bg-transparent">
                                                    <OverLay className="e-bg-overlay " isOpen={startDateToggle} onClose={_handleCloseStartDateModal} />
                                                    <CustomDatePicker type="modal"
                                                        selected={selectedStartDate}
                                                        onSelect={_handleSelectedStartDate} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                {/* End date picker */}
                                <div className='col-6 ps-2' id="end-date">
                                    <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                        End date<span className='e-text-lust'>*</span>
                                    </p>
                                    <div className='pt-2 h-70px position-relative'>
                                        <div className={`${endDateToggle ? 'e-border-1px-indigo' : 'e-border-1px-platinum-light'} ${selectedStartDate === null ? 'e-disable' : ''} e-display-flex gap-0-4px e-border-radius-6 padding-14px-tb px-2 cursor-pointer`}
                                            onClick={_handleEndDateToggle}>
                                            <img src={require("../../Assets/Image/CountryAdmin/Notification/calendar-icon.svg").default}
                                                draggable={false}
                                                alt='calender-icon' />
                                            {selectedEndDate !== null ?
                                                <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                                    {format(selectedEndDate, 'PP')}
                                                </p>
                                                :
                                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                                    Choose end date
                                                </p>
                                            }
                                        </div>
                                        <p className='mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px padding-2-top'>
                                            {endDateError}
                                        </p>

                                        {selectedEndDate !== null &&
                                            <Icon icon="close-icon"
                                                className='position-absolute top-20px right-8px cursor-pointer'
                                                size="20px"
                                                onPress={() => {
                                                    setSelectedEndDate(null);
                                                }} />
                                        }
                                    </div>

                                    {/*End date picker */}
                                    {endDateToggle &&
                                        <div className="modal fade" id="end-date-modal" data-bs-backdrop="false" tabIndex="-1" aria-labelledby="endDateModalLabel" aria-hidden="true" >
                                            <div className="modal-dialog modal-dialog-centered e-border-radius-24">
                                                <button id='end-date-modal-close'
                                                    className='d-none '
                                                    data-bs-dismiss="modal" />
                                                <div className="modal-content w-358px  mx-auto border-0 bg-transparent">
                                                    <OverLay className="e-bg-overlay " isOpen={endDateToggle} onClose={_handleCloseEndDateModal} />
                                                    <CustomDatePicker type="modal"
                                                        selected={selectedEndDate}
                                                        minDate={selectedStartDate}
                                                        onSelect={_handleSelectedEndDate} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className='row pt-3 px-4 pb-4'>
                        <div className='col-6'>
                            <SecondaryButton name="Cancel"
                                className="w-100"
                                onPress={_handleCloseModal} />
                        </div>
                        <div className='col-6'>
                            <PrimaryButton name={kpiId === null ? "Add widget" : "Save widget"}
                                className="w-100"
                                status={createLoader}
                                onPress={_handleManageKpi} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageKpiModal;
