/*
 *   File : manage-video.js
 *   Author : https://evoqins.com
 *   Description : Manage course page
 *   Integrations : react-toastify,js-cookie
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

// Custom component 
import { CustomFileUpload, CustomSelectBox, CustomTextInput, TagSelector } from '../../../Component/FormElements';
import { PrimaryButton, TextButton } from '../../../Component/Buttons';
import { Icon } from '../../../Component/Icon';
import { APIService, AWSService } from '../../../Service';
import { Loader } from '../../../Component/Others';

const VIDEO_TYPE = [
    {
        value: 1,
        label: 'Product',
    },
    {
        value: 2,
        label: 'Skills & Services'
    },
];

const ManageVideo = () => {

    const navigate = useNavigate();
    const { state } = useLocation();

    const [adminType, setAdminType] = useState(Cookies.get("admin_type") === "1" ? "super_admin" : "country_admin");
    const [resourceName, setResourceName] = useState("");
    const [resourceNameError, setResourceNameError] = useState("");
    const [uploadedThumbnail, setUploadedThumbnail] = useState(null);
    const [thumbnailError, setThumbnailError] = useState("");
    const [rewardPoint, setRewardPoint] = useState("");
    const [rewardPointError, setRewardPointError] = useState("");
    const [tagValue, setTagValue] = useState([]);

    const [selectedVideoType, setSelectedVideoType] = useState(null);
    const [selectedVideoTypeError, setSelectedVideoTypeError] = useState("");

    const [duration, setDuration] = useState("");
    const [durationError, setDurationError] = useState("");

    const [uploadedVideo, setUploadedVideo] = useState(null);
    const [uploadedVideoError, setUploadedVideoError] = useState("");

    const [createLoader, setCreateLoader] = useState(true);
    const [uploadLoader, setUploadLoader] = useState(true);
    const [videoLoader, setVideoLoader] = useState(true);

    const [loading, setLoading] = useState(true);

    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [countrySelect, setCountrySelect] = useState(false);

    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [categorySelect, setCategorySelect] = useState(false);
    const [errorUpdate, setErrorUpdate] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
        setUploadLoader(false);
        setVideoLoader(false);

        _getCategoryList();
    }, []);

    useEffect(() => {
        if (state !== null) {
            if (state.type === "edit") {
                setLoading(true);
                _getVideoDetail();
            } else {
                setLoading(false);
            }
        } else {
            navigate("/manage-resource", {
                state: 2
            });
        }
        setCreateLoader(false);
    }, [state]);

    useEffect(() => {
        if (adminType === "super_admin") {
            _getCountries();
        }
    }, [adminType]);

    useEffect(() => {
        setResourceNameError("");
    }, [resourceName]);

    useEffect(() => {
        setThumbnailError("");
    }, [uploadedThumbnail]);

    useEffect(() => {
        setSelectedVideoTypeError("");
    }, [selectedVideoType]);

    useEffect(() => {
        setUploadedVideoError("");
    }, [uploadedVideo]);

    useEffect(() => {
        setRewardPointError("");
    }, [rewardPoint]);

    useEffect(() => {
        setDurationError("");
    }, [duration]);

    // handle upload thumbnail
    const _uploadedImage = (url) => {
        setUploadLoader(true);
        _getPresignedUrl(url, true);
    };

    // handle upload video
    const _handleUploadVideo = (url) => {
        setVideoLoader(true);
        _getPresignedUrl(url, false);
    }

    // handle tag change
    const _handleTagChange = (value) => {
        setTagValue(value);
    };

    // handle Category change
    const _handleCategoryChange = (value) => {
        setSelectedCategories(value);
    };

    // handle save data
    const _handleSave = () => {

        let is_true = true;

        if (resourceName.trim().length === 0) {
            setResourceNameError("Enter resource name");
            is_true = false;
        }
        if (uploadedThumbnail === null) {
            setThumbnailError("Thumbnail is required");
            is_true = false;
        }
        if (rewardPoint.length === 0) {
            setRewardPointError("Reward point is required");
            is_true = false;
        }
        // if (tagValue.length === 0) {
        //     setTagValueError("Select tag")
        //     is_true = false;
        // }

        if (selectedVideoType === null) {
            setSelectedVideoTypeError("Select video type");
            is_true = false;
        }

        if (duration.length === 0) {
            setDurationError("Watch time is required");
            is_true = false;
        }

        if (uploadedVideo === null) {
            setUploadedVideoError("Video is required");
            is_true = false;
        }

        setErrorUpdate(prev => !prev);

        if (is_true) {
            setCreateLoader(true);

            if (state.type === "edit") {
                _updateVideo();
            } else {
                _createVideo();
            }
        }
    };

    // handle back
    const _handleBack = (id) => {
        navigate("/manage-resource", {
            state: id
        });
    };

    // handle Country change
    const _handleCountryChange = (value) => {
        setSelectedCountries(value);
    };

    // get presigned url
    async function _getPresignedUrl(file, type) {
        let is_post = true;
        let url = 'general/file-upload';
        let data = {
            "file": file.name,
            "is_public": true
        };
        try {
            let response = await APIService(is_post, url, data);
            if (response.status_code === 200) {
                let aws_fields = response.data.upload_info.fields;
                let aws_url = response.data.upload_info.url;
                try {
                    let aws_response = await AWSService(aws_fields, file, aws_url);
                    if (aws_response.status === 'ok') {

                        if (type) {
                            setUploadedThumbnail(response.data.view_info);
                        } else {
                            setUploadedVideo(response.data.view_info);
                        }
                    } else {
                        toast.dismiss();
                        toast.error(aws_response.message, {
                            type: "error",
                            className: "e-toast",
                        });
                    }
                } catch (err) {
                    console.log("error")
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        } catch (err) {
        }
        setUploadLoader(false);
        setVideoLoader(false);
    };

    // API - Create new video
    const _createVideo = () => {

        const updated_tag = tagValue.length !== 0 ? tagValue.map(item => item.label) : null;
        const updated_country_ids = selectedCountries.map(item => item.value);
        const updated_categories = selectedCategories.map(item => item.value);

        let is_post = true;
        let url = adminType === "super_admin" ? 'video/create' : 'CA/video/create';
        let data = {
            "title": resourceName,
            "description": "test video description",
            "thumbnail_url": uploadedThumbnail,
            "type": selectedVideoType.value,
            "video_url": uploadedVideo,
            "tags": updated_tag,
            "reward_points": rewardPoint,
            "duration": duration,
            "product_categories": updated_categories,
            "country_ids": updated_country_ids
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Video created successfully", {
                    type: "success",
                    className: "e-toast",
                });

                navigate("/manage-resource", {
                    state: 2
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setCreateLoader(false);
        });
    };

    // API - Update video
    const _updateVideo = () => {

        const updated_tag = tagValue.length !== 0 ? tagValue.map(item => item.label) : null;
        const updated_country_ids = selectedCountries.map(item => item.value);
        const updated_categories = selectedCategories.map(item => item.value);

        let is_post = true;
        let url = adminType === "super_admin" ? 'video/update' : 'CA/video/update';
        let data = {
            "video_id": state.video_id,
            "title": resourceName,
            "description": "test video description",
            "thumbnail_url": uploadedThumbnail,
            "type": selectedVideoType.value,
            "video_url": uploadedVideo,
            "tags": updated_tag,
            "reward_points": rewardPoint,
            "duration": duration,
            "product_categories": updated_categories,
            "country_ids": adminType === "super_admin" ? updated_country_ids : []
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Video updated successfully", {
                    type: "success",
                    className: "e-toast",
                });

                navigate("/manage-resource", {
                    state: 2
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setCreateLoader(false);
        });
    };

    // API - Video detail
    const _getVideoDetail = () => {
        let is_post = true;
        let url = adminType === "super_admin" ? 'video/detail' : 'CA/video/detail';
        let data = {
            "video_id": state.video_id
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                let video_data = response.data;

                setResourceName(video_data.name);
                setUploadedThumbnail(video_data.thumbnail_url);
                setUploadedVideo(video_data.url);
                setRewardPoint(video_data.points);
                setDuration(video_data.duration_text);
                setSelectedVideoType({ value: video_data.type, label: video_data.type_text });

                if (video_data.tags !== null) {
                    const updatedTags = video_data.tags.map((tag, index) => ({ value: index + 3, label: tag }));
                    setTagValue(updatedTags);
                }
                if (countrySelect === true) {
                    setSelectedCountries(video_data.country_ids);
                }
                setSelectedCategories(video_data.product_categories);

                setLoading(false);
            } else {
                navigate("/manage-resource", {
                    state: 2
                });
            }

        });
    };

    // API - Get countries
    const _getCountries = () => {
        let is_post = false;
        let url = 'general/list-countries';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCountries(response.data);
                setCountrySelect(true);
            } else {
                setCountrySelect(false);
            }
        });
    };

    // API - Get Category list
    const _getCategoryList = () => {
        let is_post = false;
        let url = 'general/filter-data';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCategoryList(response.data);
                setCategorySelect(true);
            } else {
                setCategorySelect(false);
            }
        });
    };

    return (
        <section className='pb-5 mb-5'>
            <div className={`${adminType === "super_admin" ? 'e-bg-azureish-white' : 'e-bg-lavender-blush-light'} pt-3 padding-98px-bottom padding-52px-lr`}>
                <div className='e-display-flex'>
                    <Icon icon="arrow-left"
                        className='cursor-pointer'
                        size="24px"
                        onPress={() => _handleBack(2)} />
                    <h1 className='ps-1 mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                        {state?.type === "edit" ? "Edit video" : "Add new video"}
                    </h1>
                </div>
            </div>

            <div className='margin-neg-66px-top padding-52px-lr'>
                {loading ?
                    // loader 
                    <Loader />
                    :
                    <div className='e-bg-white e-admin-box-shadow p-4 e-border-radius-16 '>
                        <div className='d-flex gap-0-8 justify-content-space-between'>
                            <h3 className='mb-0 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px'>
                                Video details
                            </h3>

                            <PrimaryButton name="Save & update"
                                className={`${createLoader ? 'padding-6px-tb' : 'py-2'} e-border-radius-16 e-font-16 w-146px`}
                                status={createLoader}
                                onPress={_handleSave} />
                        </div>

                        {/* input section */}
                        <div className='row'>
                            <div className='col-xl-9 col-lg-9 col-md-10 col-12'>

                                {/* resource name  */}
                                <CustomTextInput label="Resource name"
                                    placeholder="Successful negotiation essential strategies & skills"
                                    type="text"
                                    require
                                    value={resourceName}
                                    error={resourceNameError}
                                    className="w-100 padding-10px-tb"
                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                    borderStyle="e-border-1px-platinum-light"
                                    handleChange={(e) => setResourceName(e)} />

                                {/* upload thumbnail */}
                                <CustomFileUpload label="Upload thumbnail"
                                    placeholder="Drop your image here"
                                    file={uploadedThumbnail}
                                    fileType={["JPG", "PNG", "JPEG"]}
                                    require
                                    resolution="328px * 149px"
                                    requiredWidth={328}
                                    requiredHeight={149}
                                    error={thumbnailError}
                                    loader={uploadLoader}
                                    errorUpdate={errorUpdate}
                                    handleChange={_uploadedImage} />
                                {/* change thumbnail button */}
                                {uploadedThumbnail !== null &&
                                    <div className='mb-2 margin-neg-16px-top'>
                                        <TextButton name="Change thumbnail"
                                            onPress={() => setUploadedThumbnail(null)} />
                                    </div>
                                }

                                {/* upload video */}
                                <>
                                    <CustomFileUpload label="Upload video"
                                        placeholder="Drop your video here"
                                        file={uploadedVideo}
                                        fileType={["MP4", "MOV"]}
                                        require
                                        type={2}
                                        support="MP4"
                                        error={uploadedVideoError}
                                        loader={videoLoader}
                                        handleChange={_handleUploadVideo} />

                                    {uploadedVideo !== null &&
                                        <div className='margin-neg-20px-top'>
                                            <TextButton name="Change video"
                                                onPress={() => setUploadedVideo(null)} />
                                        </div>
                                    }
                                </>

                                {/* tag selector */}
                                <TagSelector label="Add tags"
                                    placeholder="Enter tag"
                                    type={2}
                                    menuIsOpen={false}
                                    value={tagValue}
                                    handleTagChange={_handleTagChange} />

                                <div className='row pt-1'>
                                    {/* Course type */}
                                    <div className='col-6'>
                                        <CustomSelectBox label="Type"
                                            value={selectedVideoType}
                                            options={VIDEO_TYPE}
                                            error={selectedVideoTypeError}
                                            className="e-section-select e-bg-white"
                                            isSearchable={false}
                                            selectChange={(id) => { setSelectedVideoType(id); }}
                                        />
                                    </div>

                                    {/* tag selector */}
                                    <div className='col-6'>
                                        {categorySelect === true &&
                                            <TagSelector label="Category"
                                                value={selectedCategories}
                                                options={categoryList}
                                                className="e-bg-white e-border-radius-12"
                                                handleTagChange={_handleCategoryChange} />
                                        }
                                    </div>
                                </div>

                                <div className='row '>
                                    {/* Reward point*/}
                                    <div className='col-6'>
                                        <CustomTextInput label="Reward point"
                                            placeholder="100"
                                            type="number"
                                            require
                                            value={rewardPoint}
                                            error={rewardPointError}
                                            className="w-100 padding-10px-tb"
                                            labelStyle="e-font-weight-500 e-text-raisin-black"
                                            borderStyle="e-border-1px-platinum-light"
                                            handleChange={(e) => setRewardPoint(e)} />
                                    </div>

                                    {/* Watch time */}
                                    <div className='col-6'>
                                        <CustomTextInput label="Watch time"
                                            placeholder="1 minutes"
                                            type="text"
                                            require
                                            value={duration}
                                            error={durationError}
                                            className="w-100 padding-10px-tb"
                                            labelStyle="e-font-weight-500 e-text-raisin-black"
                                            borderStyle="e-border-1px-platinum-light"
                                            handleChange={(e) => setDuration(e)} />
                                    </div>
                                </div>

                                {/* Countries select */}
                                {countrySelect === true &&
                                    <div className='pt-2'>
                                        <h3 className='mb-3 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px'>
                                            {state.type === "edit" ? "Manage Countries" : "Countries"}
                                        </h3>
                                        <div className='e-bg-ghost-white p-3 e-border-radius-16'>
                                            {/* Country select */}
                                            <TagSelector label="Country"
                                                value={selectedCountries}
                                                options={countries}
                                                className="e-bg-white e-border-radius-12"
                                                handleTagChange={_handleCountryChange} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </section>
    )
}

export default ManageVideo;