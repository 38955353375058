/*
 *   File : course-detail.js
 *   Author : https://evoqins.com
 *   Description : course detail page
 *   Integrations : react-toastify,js-cookie,html-react-parser
 *   Version : 1.0.0
*/

import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import Cookies from 'js-cookie';

// Custom components
import { TextArea } from '../../../Component/FormElements';
import { CustomVideoPlayer } from '../../../Component/Video';
import { PrimaryButton, TextButton } from '../../../Component/Buttons';
import { APIService } from '../../../Service';
import { ConfirmDeleteModal, QuizDetailModal } from '../../../Component/Modal';
import { DownloadCard, QuizCard } from '../../../Component/Cards';
import { EmptyScreen } from '../../../Component/EmptyComponent';
import { Loader } from '../../../Component/Others';

//assets
// import Loader from "../../../Assets/loader-dark.gif";

const CATEGORY_TAB = [
    {
        id: 1,
        name: "Modules"
    },
    {
        id: 2,
        name: "Quiz"
    },
    {
        id: 3,
        name: "Downloads"
    },
    {
        id: 4,
        name: "Products"
    },
    {
        id: 5,
        name: "Other options"
    },
];

const CourseDetail = () => {

    const navigate = useNavigate();
    const { state } = useLocation();
    const videoRef = useRef(null);

    const [adminType, setAdminType] = useState(Cookies.get("admin_type") === "1" ? "super_admin" : "country_admin");

    const [tabIndex, setTabIndex] = useState(1);
    const [message, setMessage] = useState("");
    const [messageError, setMessageError] = useState("");
    const [announcementChange, setAnnouncementChange] = useState(false);
    const [addAnnouncementLoading, setAddAnnouncementLoading] = useState(true);

    const [play, setPlay] = useState(false);
    const [videoURL, setVideoURL] = useState(null);

    const [playVideo, setPlayVideo] = useState(true);

    const [detailData, setDetailData] = useState({});
    const [loading, setLoading] = useState(true);

    const [courseId, setCourseId] = useState(null);
    const [deleteResponse, setDeleteResponse] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);

    const [quizDetailData, setQuizDetailData] = useState({});
    const [quizLoading, setQuizLoading] = useState(true);

    const [deleteId, setDeleteId] = useState(null);
    const [deleteResponseQuiz, setDeleteResponseQuiz] = useState(false);


    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

    }, []);

    useEffect(() => {
        const opened_modal = document.querySelector('.modal.show');
        if (opened_modal) {
            const modal = Modal.getInstance(opened_modal);
            modal.hide();
        }
    }, [state]);

    useEffect(() => {
        if (tabIndex === 2 && !loading && detailData.is_quiz_available) {
            setQuizLoading(true);
            _getQuizDetail();
        }
    }, [tabIndex, loading]);

    useEffect(() => {
        setMessageError("");
    }, [message]);

    useEffect(() => {
        if (state !== null) {
            if (state.tab_id) {
                setTabIndex(state.tab_id);
            } else {
                setTabIndex(1);
            }
        } else {
            navigate("/manage-resource");
        }
    }, [state]);

    useEffect(() => {
        if (state !== null) {
            _getCourseDetail();
        } else {
            navigate("/manage-resource");
        }
        setAddAnnouncementLoading(false);
    }, []);

    useEffect(() => {
        if (detailData.announcement !== null) {
            setMessage(detailData.announcement);
        }
    }, [detailData]);

    useEffect(() => {
        if (deleteModal !== null) {
            const delete_modal = new Modal(document.getElementById('confirm-delete-modal'));
            delete_modal.show();
        }
    }, [deleteModal]);

    // handle module resources
    const _handleClickModule = (data) => {
        if (data.type === "VIDEO") {
            setPlay(true);
            setPlayVideo(true);
            setVideoURL(data.course_url);
            if (videoRef.current) {
                videoRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.open(data.course_url, '_blank');
        }
    };

    // handle end video
    const _handleVideoEnded = () => {
        setPlayVideo(false);
    }

    // handle tab change 
    const _handleTabChange = (id) => {
        navigate("/manage-resource/course-detail", {
            state: {
                tab_id: id,
                course_id: state.course_id
            }
        });

        setVideoURL(null);
    };

    // handle save announcement 
    const _handleSaveAnnouncement = () => {
        if (message.trim().length === 0) {
            setMessageError("Message is required");
            return;
        }
        setAddAnnouncementLoading(true);
        _addAnnouncement();
    }

    // handle edit course
    const _handleEdit = (course_id) => {
        navigate("/manage-resource/manage-course", {
            state: {
                type: "edit",
                course_id: course_id
            }
        });
    };

    // handle delete 
    const _handleDeleteCourse = (course_id) => {
        setCourseId(course_id);
        setDeleteResponse(false);

        setDeleteModal(true);
    };

    // handle manage quiz 
    const _handleManageQuiz = (id) => {
        navigate('/manage-resource/manage-quiz', {
            state: {
                course_id: id,
                type: ""
            }
        });
    };

    // handle edit quiz
    const _handleEditQuiz = (course_id) => {
        navigate('/manage-resource/manage-quiz', {
            state: {
                course_id: course_id,
                type: "edit"
            }
        });
    };

    // handle delete quiz
    const _handleDeleteQuiz = (id) => {
        setDeleteId(id);
        setDeleteResponseQuiz(false);
        setDeleteModal(false);
    };

    // handle manage download 
    const _handleManageDownload = (id) => {
        navigate('/manage-resource/manage-download', {
            state: {
                course_id: id,
                type: ""
            }
        });
    }

    // handle manage download 
    const _handleEditDownload = (id) => {
        navigate('/manage-resource/manage-download', {
            state: {
                course_id: id,
                type: "edit"
            }
        });
    }

    // common function for get resource icon
    const _getResourceIcon = (type) => {
        switch (type) {
            case "VIDEO":
                return require("../../../Assets/Image/CountryAdmin/play-icon.svg").default;
            case "DOC":
                return require("../../../Assets/Image/CountryAdmin/doc-icon.svg").default;
            case "PDF":
                return require("../../../Assets/Image/CountryAdmin/pdf-icon.svg").default;
            case "PPT":
                return require("../../../Assets/Image/CountryAdmin/ppt-icon.svg").default;
            default:
                return null;
        }
    };

    // API - Course detail
    const _getCourseDetail = () => {

        let is_post = true;
        let url = adminType === "super_admin" ? 'course/detail' : 'CA/course/detail';
        let data = {
            "course_id": state.course_id
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setDetailData(response.data);
            } else {

            }
            setLoading(false);
        });
    };

    // API - Delete course 
    const _deleteCourse = () => {
        let is_post = true;
        let url = adminType === "super_admin" ? 'course/delete' : 'CA/course/delete';
        let data = {
            "course_id": courseId
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setDeleteResponse(true);
            } else {
                setDeleteResponse(false);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    // API - Add announcement
    const _addAnnouncement = () => {
        let is_post = true;
        let url = adminType === "super_admin" ? 'course/add-announcement' : 'CA/course/add-announcement';
        let data = {
            "course_id": state.course_id,
            "announcement": message
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setMessage("");
                setAnnouncementChange(false);
                _getCourseDetail();

                toast.dismiss();
                toast.success("Announcement added successfully", {
                    type: "success",
                    className: "e-toast",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setAddAnnouncementLoading(false);
        });
    };

    // API - Quiz detail
    const _getQuizDetail = () => {
        let is_post = true;
        let url = adminType === "super_admin" ? 'course/quiz-detail' : 'CA/course/quiz-detail';
        let data = {
            "course_id": detailData.course_id
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setQuizDetailData(response.data);
            } else {

            }
            setQuizLoading(false);
        });
    };

    // API - Delete quiz 
    const _deleteQuiz = () => {
        let is_post = true;
        let url = adminType === "super_admin" ? 'course/delete-quiz' : 'CA/course/delete-quiz';
        let data = {
            "course_id": deleteId
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setDeleteResponseQuiz(true);
            } else {
                setDeleteResponseQuiz(false);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    return (
        <section className='pb-5 '>
            <div className={`${adminType === "super_admin" ? 'e-bg-azureish-white' : 'e-bg-lavender-blush-light'} pt-3 padding-98px-bottom padding-52px-lr`}>
                <div className='e-display-flex justify-content-space-between'>
                    <div className='e-display-flex'>
                        <img src={require("../../../Assets/Image/CountryAdmin/arrow-left.svg").default}
                            draggable={false}
                            className='cursor-pointer'
                            alt='arrow'
                            onClick={() => navigate("/manage-resource")} />
                        <h1 className='ps-1 mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                            Course detail
                        </h1>
                    </div>
                    <div className='e-inline-flex padding-56px-right gap-0-24px'>
                        <TextButton name="Edit"
                            className="e-font-16"
                            onPress={() => _handleEdit(state.course_id)} />
                        <TextButton name="Delete"
                            className="e-text-lust e-font-16"
                            onPress={() => _handleDeleteCourse(state.course_id)} />
                    </div>
                </div>
            </div>

            <div ref={videoRef} className='margin-neg-66px-top padding-52px-lr'>
                <div className='e-bg-white e-admin-box-shadow p-4 e-border-radius-16 '>
                    {loading ?
                        // loader 
                        <Loader />

                        :
                        <>
                            <div className=''>
                                <h3 className='mb-1 e-alt-font-noto-sans e-text-raisin-black e-font-20 e-font-weight-600 line-height-28px'>
                                    {detailData.title}
                                </h3>
                                <span className='e-bg-periwinkle e-border-radius-20 padding-6px-lr e-alt-font-noto-sans e-text-raisin-black e-font-14 e-font-weight-400 line-height-20px'>
                                    {detailData.type_text}
                                </span>
                            </div>

                            <p className='mb-2 pb-4 pt-2 e-alt-font-noto-sans e-text-raisin-black e-font-14 e-font-weight-400 line-height-20px'>
                                {detailData.description}
                            </p>

                            {/* video play and thumbnail */}
                            {videoURL !== null && playVideo ?
                                <CustomVideoPlayer url={videoURL}
                                    className="h-324px"
                                    controls={true}
                                    playing={play}
                                    onEnded={_handleVideoEnded} />
                                :
                                <div className='text-center e-bg-black e-border-radius-16 overflow-hidden'>
                                    <img src={detailData.thumbnail_url}
                                        draggable={false}
                                        className='h-324px'
                                        alt='thumbnail' />
                                </div>
                            }

                            {/* Summary  */}
                            <div className='mt-4 e-admin-box-shadow p-4 e-bg-magnolia e-border-radius-16'>
                                <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                                    Course information
                                </h4>
                                <div className='row row-cols-4 padding-10px-top'>
                                    <div className='col'>
                                        <div className='e-bg-white p-3 e-border-radius-16'>
                                            <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-24px'>
                                                Last update
                                            </p>
                                            <p className='mb-0 pt-3 e-text-charleston-green e-alt-font-noto-sans e-font-18 e-font-weight-600 line-height-24px'>
                                                {detailData.last_updated}
                                            </p>
                                        </div>
                                    </div>

                                    <div className='col'>
                                        <div className='e-bg-white p-3 e-border-radius-16'>
                                            <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-24px'>
                                                Language
                                            </p>
                                            <p className='mb-0 pt-3 e-text-charleston-green e-alt-font-noto-sans e-font-18 e-font-weight-600 line-height-24px'>
                                                {detailData.language}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='e-bg-white p-3 e-border-radius-16'>
                                            <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-24px'>
                                                Created by
                                            </p>
                                            <p className='mb-0 pt-3 e-text-charleston-green e-alt-font-noto-sans e-font-18 e-font-weight-600 line-height-24px'>
                                                {detailData.created_by}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* category tab */}
                            <div className='d-flex gap-0-16 padding-40px-top'>
                                {CATEGORY_TAB.map((data, key) => (
                                    <div key={key} className={`${tabIndex === data.id ? 'e-bg-lust e-text-white border-transparent pe-none' : 'e-text-raisin-black e-border-1px-cool-grey'} transition e-border-radius-24 cursor-pointer`}
                                        onClick={() => _handleTabChange(data.id)} >
                                        <p className='mb-0 px-4 padding-12px-tb e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-14px'>
                                            {data.name}
                                        </p>
                                    </div>
                                ))}
                            </div>

                            {/* Added sections */}
                            {tabIndex === 1 ?
                                <div className='pt-4 mt-2'>
                                    {detailData.course_type_id === 1 ?
                                        // added module data
                                        <>
                                            <h4 className='mb-3 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                                                Added modules
                                            </h4>

                                            {detailData.modules.map((data, key) => (
                                                <div key={key} className={`${key !== detailData.modules.length - 1 && `mb-3`} e-bg-magnolia p-3 e-border-radius-16 cursor-pointer`}
                                                    onClick={() => _handleClickModule(data)}>
                                                    <div className='row'>
                                                        <div className='col-8'>
                                                            <div className='d-flex align-items-start gap-0-8'>
                                                                <img src={_getResourceIcon(data.type)}
                                                                    draggable={false}
                                                                    className='cursor-pointer'
                                                                    alt='play' />

                                                                <div>
                                                                    <h6 className='mb-2 e-alt-font-noto-sans e-text-raisin-black e-font-16 e-font-weight-400 line-height-22px'>
                                                                        {data.title}
                                                                    </h6>
                                                                    <p className='mb-0 e-alt-font-noto-sans e-text-black-olive e-font-14 e-font-weight-400 line-height-20px'>
                                                                        {data.description}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                        :
                                        // html content data
                                        <>
                                            <h4 className='mb-3 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                                                Content
                                            </h4>
                                            <div className='e-html-parser'>
                                                {parse(detailData.modules.content)}
                                            </div>
                                        </>
                                    }
                                </div>
                                :
                                tabIndex === 2 ?
                                    // Normal quiz card
                                    <>
                                        {detailData.is_quiz_available ?
                                            <>
                                                {quizLoading ?
                                                    // loader 
                                                    <Loader className="h-30vh" />
                                                    :
                                                    <>
                                                        {/* Button */}
                                                        <div className='pt-3 text-end'>
                                                            <PrimaryButton name="Edit quiz"
                                                                className="e-border-radius-16 padding-14px-tb line-height-12px e-font-16"
                                                                onPress={() => _handleEditQuiz(detailData.course_id)} />
                                                        </div>

                                                        {/* Quiz card */}
                                                        <div className='row row-gap-3 pt-4 mt-2 pb-5'>
                                                            <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                                                                <QuizCard data={quizDetailData} />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </>
                                            :
                                            <div className='min-h-30vh'>
                                                <div className='pt-3 text-end'>
                                                    <PrimaryButton name="Add new quiz"
                                                        className="e-border-radius-16 padding-14px-tb line-height-12px e-font-16"
                                                        onPress={() => _handleManageQuiz(detailData.course_id)} />
                                                </div>
                                                <EmptyScreen message="No quiz found" />
                                            </div>
                                        }
                                    </>
                                    :

                                    tabIndex === 3 ?
                                        <>
                                            {detailData.downloads.length === 0 ?
                                                <div className='min-h-30vh'>
                                                    <div className='pt-3 text-end'>
                                                        <PrimaryButton name="Add downloads"
                                                            className="e-border-radius-16 padding-14px-tb line-height-12px e-font-16"
                                                            onPress={() => _handleManageDownload(detailData.course_id)} />
                                                    </div>
                                                    {/* empty screen */}
                                                    <EmptyScreen message="No downloads found" />
                                                </div>
                                                :
                                                <>
                                                    <div className='pt-3 text-end'>
                                                        <PrimaryButton name="Edit downloads"
                                                            className="e-border-radius-16 padding-14px-tb line-height-12px e-font-16"
                                                            onPress={() => _handleEditDownload(detailData.course_id)} />
                                                    </div>

                                                    {/* downloads card */}
                                                    <div className='row pt-4'>
                                                        {detailData.downloads.map((data, key) => (
                                                            <div key={key} className='col-xl-6 pb-3'>
                                                                <DownloadCard download={data} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            }
                                        </>
                                        :

                                        tabIndex === 4 ?
                                            <div className=' pt-4 mt-2'>
                                                {detailData.linked_products.length !== 0 ?
                                                    <>
                                                        {detailData.linked_products.map((data, key) => (
                                                            <div key={key} className='mb-3 e-bg-magnolia p-3 e-border-radius-16'>
                                                                <div className='d-flex gap-0-16 align-items-center'>
                                                                    <div>
                                                                        <img src={data.thumbnail_url}
                                                                            width={80}
                                                                            height={90}
                                                                            draggable={false}
                                                                            alt='product' />
                                                                    </div>
                                                                    <div>
                                                                        <p className='mb-2 e-alt-font-noto-sans e-text-raisin-black e-font-16 e-font-weight-600 line-height-22px'>
                                                                            {data.title}
                                                                        </p>
                                                                        <p className='mb-0 e-alt-font-noto-sans e-text-black-olive e-font-14 e-font-weight-400 line-height-20px'>
                                                                            {data.description}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                    :
                                                    <div className='min-h-30vh pt-3'>
                                                        {/* empty screen */}
                                                        <EmptyScreen message="No products found" />
                                                    </div>
                                                }
                                            </div>
                                            :
                                            // add more information section
                                            <div className='pt-4 mt-2'>
                                                <h4 className='mb-3 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                                                    Add more information
                                                </h4>

                                                {/* Text area */}
                                                <TextArea label="Make announcements"
                                                    placeholder="Enter your text..."
                                                    type="text"
                                                    rows="3"
                                                    cols="100"
                                                    value={message}
                                                    error={messageError}
                                                    className=" px-3 padding-12px-tb"
                                                    handleChange={(e) => {
                                                        setMessage(e);
                                                        setAnnouncementChange(true);
                                                    }} />

                                                <div className='pt-3'>
                                                    <PrimaryButton name="Save"
                                                        className={`${!announcementChange ? 'e-disable' : ''} ${addAnnouncementLoading ? "py-2 pe-none" : ""} padding-10px-tb px-4`}
                                                        status={addAnnouncementLoading}
                                                        onPress={_handleSaveAnnouncement} />
                                                </div>
                                            </div>
                            }
                        </>
                    }
                </div>
            </div>

            {/* confirm delete modal */}
            {deleteModal === true ?
                <ConfirmDeleteModal label="course"
                    deleteId={courseId}
                    delete={() => _deleteCourse()}
                    success={() => navigate("/manage-resource")}
                    handleClose={() => setDeleteModal(null)}
                    response={deleteResponse} />
                :
                deleteModal === false ?
                    <ConfirmDeleteModal label="quiz"
                        deleteId={deleteId}
                        response={deleteResponseQuiz}
                        delete={() => _deleteQuiz()}
                        handleClose={() => setDeleteModal(null)}
                        success={() => _getCourseDetail()} />
                    : null
            }

            {/* quiz detail modal */}
            {quizLoading === false &&
                <QuizDetailModal quiz={quizDetailData}
                    response={deleteResponseQuiz}
                    handleDelete={_handleDeleteQuiz} />
            }
        </section>
    )
}

export default CourseDetail;